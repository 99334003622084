@import "_vars.scss";
@import "_sprite.scss";
//==========TRIMMING TEXT==========//
@mixin text_ellipsis($overflow: ellipsis){// values are: clip, ellipsis, or a string
	overflow: hidden;
	white-space: nowrap;
	text-overflow: $overflow;
}

////////////////////////////////////////////////////////////////PX TO REM
@function rem($pxsize) {
    @return ($pxsize/$root-font-size)+rem;
}

@function em($pxsize, $parent-size: $root-font-size) {
    @return ($pxsize/$parent-size)+em;
}

/////////////////////////////////////////////////////////////////CLEARFIX
@mixin cf {
	&::after,
	&::before{
		content: '';
		display: table;
		width: 100%;
	}
	&::after{
		clear: both;
	}
}
///////////////////////////////////////////////////////////////SELECTION
@mixin selection {
	&::selection {
		@content;
	}
}


%clean-ul{
	margin: 0;
	padding: 0;
	list-style: none;
}

//==========FONT SMOOTHING==========//
@mixin font_smoothing($value: antialiased) {
  @if $value == antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -moz-font-smoothing: $value;
		-ms-font-smoothing: $value;
		-o-font-smoothing: $value;
  }
  @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
    -moz-font-smoothing: $value;
		-ms-font-smoothing: $value;
		-o-font-smoothing: $value;
  }
}

//==========ABSILUTE PSEUDO==========//
@mixin absolut_pseudo {
  position: relative;
  &:before,&:after {
    content: "";
    position: absolute;
		@content;
  }
}

//==========COVER LAYER==========//
@mixin coverlayer($z: 0) {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: $z;
}

//==========CENTER BLOCK==========//
@mixin center_block {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

//==========SCROLLBAR==========//
@mixin scrollbar {
  &::-webkit-scrollbar {
    @content;
  }
}
@mixin scrollbar-track {
  &::-webkit-scrollbar-track {
    @content;
  }
}
@mixin scrollbar-thumb {
  &::-webkit-scrollbar-thumb {
    @content;
  }
}
@mixin scrollbar-track-piece {
  &::-webkit-scrollbar-track-piece {
    @content;
  }
}

//==========HYPHENATION WORDS==========//

@mixin hyphens{
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;

}


///////////////////////////////////////////////////////state (hover/active) mixin
@mixin state {
	&:hover,
	&:active{
		@content;
	}
}

/////////////////////////////////////////////////////////pseudo both
@mixin pseudo-both($cont: '') {
	&::before,
	&::after{
		content: $cont;
		@content;
	}
}

///////////////////////////////////////////////////////Sizes mixin
@mixin size($height, $width: $height) {
	height: $height;
	width: $width;
}

///////////////////////////////////////////////////////////////////////////////////////placeholder
@mixin placeholder {
	$placeholders: ":-webkit-input" ":-moz" "-moz" "-ms-input";
	&[placeholder] {
		text-overflow:ellipsis; //if the word does not fit - three points in the end
	}
	@each $placeholder in $placeholders {
		&:#{$placeholder}-placeholder {
			text-overflow:ellipsis; //if the word does not fit - three points in the end
			@content;
		}
	}
}

///////////////////////////////////////////////////////////////////////////////////////palceholder:focus
@mixin placeholder--focus {
	$placeholders: ":-webkit-input" ":-moz" "-moz" "-ms-input";
	@each $placeholder in $placeholders {
		&:focus:#{$placeholder}-placeholder {
			color: transparent;//hideplaceholder if :focus state
		}
	}
}

/////////////////////////////////////////////////////////////////////////////////////////////MEDIA
$breakpoints: (
  tel: 768px,
  tablet: 992px,
  default: 1024px,
	large: rem($template-container + 1)
);

@mixin media($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (max-width: #{map-get($breakpoints, $breakpoint)}) {
      @content;
    }
  }
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Please make sure it is defined in `$breakpoints` map.";
  }
}


//////////////////////////////////////////////////////////////////////////////////////////MEDIA CUSTOM
@mixin media-custom($size) {
	@media screen and (max-width: $size){
		@content;
	}
}
//////////////////////////////////////////////////////////////////////////////////////////RETINA 2x
@mixin retina {
	@media (min-resolution: 144dpi), (min-resolution: 1.5dppx) {
		@content;
	}
}


//////////////////////////////////////////////////////////////////////////////////////////////////////////// CALC WIDTH
@function calcWidth($width) {
  @return ($width/$template-container * 100%);
}


////////////////////////////////////////////////////////////////////////////UNDERLINE
@mixin underline($transition) {
  &::after {
      content: '';
      background-color: $theme_color;
      position: absolute;
      left: 0;
      bottom: -2px;
      transition: width $transition ease;
      @include size(1px, 0);
  }
}

@mixin underline-hovered{
  &::after {
      @include size(1px, 100%);
  }
}
////////////////////////////////////////////////enter button lines
@mixin enter-lines($w,$h){
  position: relative;
  display: block;
  width: rem($w);
  height: rem($h);
  border-top: 1px solid currentColor;
  border-left: 1px solid currentColor;
  border-bottom: 1px solid currentColor;
  border-right: none;
  &::after{
    content: '→';
    position: absolute;
    color: currentColor;
    top: 50%;
    transform: translateY(-50%);
    right: -2px;
    text-indent: 0;
  }
}
