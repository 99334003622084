@import "_includs/_mixins.scss";

.ask{
  position: fixed;
  right: 0;
  bottom: 0;
  width: 258px;
  color: #fff;
  z-index: 1010;

  &--modal{
    position: relative;
    width: 500px ;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;

    .mfp-close{
      display: none !important;
    }

    .txt{
      font-size: 15px;
      color: #fff;

      a{
        font-style: italic;
        color: $theme-color;
        &:hover{
          text-decoration: underline;
        }
      }
    }
  }

  transition: all 0.3s;

  .register &{
    position: absolute;
  }

  &.hidden{
    transform: translate(100%,100%);
  }
}
.ask__header{
  background-color: rgba(#000, 0.81);
  border-bottom: 1px solid #181818;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 11px 9px 11px 16px;

  span{
    color: #4db3d2;
    text-transform: uppercase;
    font-size: 19px;
    font-family: 'Univers';
  }
}
.ask__body{
  background-color: rgba(#000, 0.71);
  padding: 0 23px 19.5px;
}
.ask__text{
  display: flex;
  align-items: center;
  color: #f0f0f0;
  padding-top: 14px;
  padding-bottom: 11px;
  .icon{
    flex-shrink: 0;
    margin-right: 10px;
    @include sprite($question);
  }
  span,
  strong{
    display: block;
    white-space: nowrap;
    letter-spacing: -0.01em;
  }
  span{
    font-size: 16px;
  }
  strong{
    line-height: (30/33);
    font-size: 33px;
    font-weight: 400;
    font-family: 'Univers';
  }

  [lang="uk"] &{
    span{
      white-space: normal;
      font-size: 15px;
    }
    strong{
      font-size: 27px;
    }
  }
}
.ask__inner{
  text-align: center;
  color: #808080;
}

// .form-group{
//
// }
