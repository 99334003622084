@import "_components/_includs/_mixins.scss";

.customer{
  .container{
    flex-wrap: wrap;
  }
}
.customer__content{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 54px;
}
.customer-content__item{
    width: calc(50% - 20px);
    padding: 42px 0 0;
    @include media(tel){
      width: 100%;
    };
}
.customer__info{
  padding-left: 12px;
  padding-right: 12px;
  align-self: center;
}
.customer__logo{
  margin-bottom: 29px;
}
.customer__desc{
  margin-bottom: 25px;
  margin-top: 0;
}
.customer__feedback{
  background-color: #f1f1f1;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 49px;
  strong{
    font-size: 40px;
    color: #212121;
    font-weight: 400;
    font-family: 'Univers';
    display: block;
    text-align: center;
    margin-bottom: 24px;
  }
  p{
    margin: 0;
    line-height: 22px;
  }
}

.customer-data{
  display: flex;
  justify-content: space-between;
  width: 100%;
  @include media(tel){
    flex-direction: column;
  };
}
.customer-data__item{
  display: flex;
  text-align: center;
  flex-basis: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 26px;
  min-height: 235px;
}
.open-letter{
  width: 280px;
  height: 379px;
  position: relative;
  outline: none;
  &:hover{
    .open-letter__cover{
      opacity: 1;
    }
  }
}
.open-letter__cover{
  @include coverlayer;
  background-color: rgba(#000,0.75);
  border: 3px solid #f1f1f1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: #fff;
  font-family: 'Univers';
  opacity: 0;
  transition: opacity 0.3s;
  .icon{
    margin-bottom: 20px;
    opacity: 0.8;
    transition: opacity 0.3s;
    @include sprite($full);
    &:hover{
      opacity: 1;
    }
  }
}

.customer-data__order{
  color: $theme-color;
  font-size: 34px;
  text-transform: uppercase;
  font-family: 'Univers';
  .icon{
    display: block;
    margin-bottom: 26px;
  }
  svg{
    fill: currentColor;
  }
  a{
    position: relative;
    @include underline(0.3s);
    &:hover{
      @include underline-hovered;

    }
  }
}
.customer-review{
  h3{
    text-align: center;
    font-size: 40px;
    color: #212121;
    margin: 20px 0;
  }
}


.holder_logos{
  .holder__image img{
    object-fit: none;
    max-width: 100%;
    height: auto;
    width: auto;
  }
}
