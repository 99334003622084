@import "_components/_includs/_mixins.scss";
.about{
  .container{
    flex-wrap: wrap;
  }
}
.mission {
  display: flex;
  justify-content: space-around;
  width: 100%;
  @include media(tel){
    flex-wrap: wrap;
  };
}
.mission__item {
  max-width: 495px;
  font-size: 19px;
  padding: 0 10px;
  @include media(tel){
    max-width: none;
    width: 100%;
    margin-bottom: 25px;
  };
  p{
    line-height: (22/19);
    margin: 0;
  }
}
.mission__title {
  display: block;
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
  color: #00899b;
}
.usage{
  width: 100%;
  padding-bottom: 15px;
  .s-header{
    padding-top: 45px;
  }
}
.usage__wrap{
  width: 100%;
  margin-top: 67px;

}

$percentage: (
  1: 100%,
  2: 99%,
  3: 97%,
	4: 69%
);
.usage__item{
  display: flex;
  align-items: center;
  margin-bottom: 53px;
  font-family: 'Univers';
  @include media(tel){
    flex-direction: column;
    margin-bottom: 30px;
  };

  @each $nth, $persent in $percentage {
    &:nth-child(#{$nth}).animated{
      .usage__diagram::after{
        width: $persent;
      }
    }
  }
}
.usage__name{
  width: 366/1200*100%;
  margin-right: 67/1200*100%;
  @include media(tel){
    width: 100%;
    margin-right: 0;
    text-align: center;
    margin-bottom: 10px;
  };
  color: #212121;
  font-size: 24px;
  text-align: right;
}


.usage__diagram{
  width: 647/1200*100%;
  @include media(tel){
    width: 100%;
  };
  height: 35px;
  position: relative;
  background-color: #e7e7e7;

  &::before,
  &::after{
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;

  }
  &::before,{
    width: 1px;
    background-color: #cfe7ea;
  }
  &::after{
    width: 0;
    background-color: #00899b;
    transition: all 1s;
    transition-timing-function: ease-in;
  }
}
.persantage{
  position: absolute;
  @include media-custom(480px){
    left: 10px;
    top: 50%;
    z-index: 100;
    color: #fff;
    transform: translateY(-50%);
  };
  right: 0;
  top: -34px;
  font-size: 24px;
  color: #6a6a6a;
  &::after{
    content: '%';
  }
}

.choice{
  position: relative;
  width: 100%;
  @include media(tablet){
    background-attachment: fixed;
  };
  .container{
    z-index: 10;
    flex-wrap: wrap;
  }

  &::after{
    content: '';
    background-color: rgba(#fff, 0.89);
    z-index: 0;
    @include coverlayer;
  }
}
.choice__heading{
  width: 100%;
  margin-bottom: 105px;
  margin-top: 65px;
  text-align: center;
  font-size: 34px;
  line-height: (32/34);
  color: #222222;
  font-family: 'Univers';
  strong{
    font-weight: normal;
    color: $theme-color;
  }
}
.choice__item{
  width: 25%;
  text-align: center;
  margin-bottom: 75px;
  @include media(tablet){
    width: 50%;
  };
  @include media(tel){
    width: 100%;
  };
}
.choice__icon{
  fill: $theme-color;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}
.choice__text{
  font-size: 19px;
  line-height: (22/19);
  strong{
    text-transform: uppercase;
    color: $theme-color;
  }
}

.team{
  padding-top: 25px;
  padding-bottom: 73px;
  .container{
    flex-direction: column;
  }
}
.team__text{
  width: 100%;
  font-size: 19px;
  line-height: (22/19);
  margin-bottom: 67px;
  p{
    margin: 0;
  }
}
.member{
  position: relative;
  margin-right: 30px;
  @include media(tel){
    margin-right: 0;
  }
}
.member__inner{
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 395px;
  @include media(tel){
    height: auto;
  };

  img{

    transition: width 0.3s;
  }
}
.member__info{
  position: absolute;
  top: 0;
  left: calc(100% + 30px);
  color: #737373;
  font-size: 23px;
  font-family: 'Univers';
  width: 250px;
  @include media(tel){
    position: static;
    margin-left: auto;
    width: 100%;
    text-align: center;
    margin-top: 15px;
    opacity: 1;
  };
  opacity: 0;
  transition: all 0.3s;
}
.member__name{
  font-size: 30px;
  color: #212121;
}
.member__position{
  line-height: (27/23);
}
.member__work-time{
  line-height: (27/23);
}
.team__slider{
  position: relative;

}
.invite{
  width: 366px;
  position: absolute;
  z-index: 10;
  @include media-custom(1024px){
    position: static;
    margin-bottom: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  };
  @include media(tel){
    flex-direction: column;
    justify-content: center;
  };
  left: 73/1200*100%;
  top: 0;
    .btn{
    margin-top: 70px;
    @include media-custom(1024px){
    margin-top: 0;
    margin-left: 15px;
    };
    @include media(tel){
      margin-left: 0;
      margin-top: 15px;
      font-size: 20px;
    };
    color: #212121;
    border-color: #212121;
    font-size: 24px;
    padding: em(14.5,24) em(20.3,24);
    &:hover{
      color: #fff;
    }
  }
}
// .invite__title{
//   text-transform: uppercase;
//   font-weight: normal;
//   color: $theme-color;
//   font-size: 28px;
//   font-family: 'Univers';
// }

.succes{
  position: relative;
  padding: percentage(100/1290) 0;
  @include media(tablet){
    background-attachment: fixed;
  };
  .container{
    z-index: 10;
    flex-wrap: wrap;
  }
  &::after{
    content: '';
    @include coverlayer;
    background-color: rgba(#000,0.63);
    z-index: 0;
  }
  p{
    width: 100%;
    font-size: 27px;
    line-height: (24/27);
    color: #fff;
    margin-top: 0;
    margin-bottom: 25px;
    @include media(tel){
      font-size: 20px;
    };
    &:last-of-type{
      margin-bottom: 0;
    }
  }
}

.about-vacancy{
  padding-bottom: 48px;
  .s-header{
    margin-bottom: 92px;
    margin-top: 18px;
  }
  .container{
    flex-wrap: wrap;
  }
}
.about-vacancy__text{
  width: 100%;
  margin-bottom: 28px;
  .title{
    color: #212121;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: bold;
    margin: 0;
    font-family: 'Calibri';
  }
  p{
    margin: 22px 0;
  }
}

.about-vacancy__item{
  height: 325px;
  margin-bottom: 15px;
  .holder__image{
    height: 325px;
    max-height: none;
  }
  width: calc(50% - 12px);
  @include media(tablet){
    width: 100%;
  };
  font-family: 'Calibri';
  p,span{
    font-size: 17px;
    line-height: (20/17);
    margin-top: 20px;
    margin-bottom: 23px;
    max-width: 550px;
  }

}
