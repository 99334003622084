@import "_includs/_mixins.scss";

.header{
    position: absolute;
    padding-top: 115px;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1000;

    // &--index{
    //   // background: transparent;
    //   // height: 100vh;
    //   // min-height: 648px;
    //   // max-height: 828px;
    //
    //   .nav{
    //     position: absolute;
    //     left: 0;
    //     right: 0;
    //     top: 0;
    //     z-index: 1000;
    //   }
    //   .container{
    //     position: relative;
    //     // z-index: 100;
    //   }
    //   // > .container{
    //   //   flex-flow: column;
    //   //   height: 100%;
    //   //   padding-top: rem(116);
    //   //   // height: 100%;
    //   // }
    // }
}



.logo{
  margin-bottom: rem(16);
  min-width: rem(190);
  @include media-custom(500px){
    min-width: inherit;
    width: 55%;
  }
}

.links{
  padding-bottom: 50px;

  position: relative;
  display: flex;
  justify-content: center;
  // justify-content: flex-end;
  width: 100%;
  @include media(tel){
    flex-direction: column;
    align-items: center;
  };

  .btn--down{
    // position: absolute;
    // bottom: 86px;
    // left: 50%;
    // margin-left: -(82px/2);
    @include media(tablet){
      bottom: -60px;
    };

    @include media(tel){
      // position: relative;
      // bottom: auto;
      // margin-left: 0;
      // left: auto;
      // margin-bottom: 25px;
    };
  }
}

///////////////////////////////////////////////mp
.video{
  position: relative;
  top:0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  overflow: hidden;
  height: 700px;
  min-height: 100vh;
  .slider--mp-header{
    opacity: 0;
    transition: opacity 0.1s;
  }
  &.inited{
    .slider--mp-header{
      opacity: 1;
    }
  }
  &::after{
    content: '';
    background-color: rgba(0 , 0, 0, 0.39);
    @include coverlayer(-1);
  }
  video{
    position: absolute;
    top: 0;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    // max-width: 100%;
    width: auto;
    height: auto;
    z-index: -100;
    transform: translateX(-50%) translateY(0);
  }

  > .container{
    flex-flow: column;
    height: 650px;
    min-height: 100vh;
    padding-top: rem(116);
    // height: 100%;

    &.mp-base-link{
      display: block;
      position: absolute;
      bottom: 80px;
      left: 50%;
      transform: translateX(-50%);
      height: auto;
      min-height: auto;
      padding: 0;

      .base-link{
        bottom: 0;
        right: 0;
        @include media-custom(1024px){
          transform: none;
        };
        @include media(tel){
          right: 50%;
          transform: translateX(50%);
        };
      }
    }
  }
}
