@import "_components/_includs/_mixins.scss";

.press-single{

}
.press-single__wrap{
  width: 100%;
  @include cf;
  p{
    margin-bottom: 23px;
    &:last-of-type{

    }
  }

  iframe{
    width: 100%;
    height: auto;
    min-height: 675px;

    @include media-custom(1150px){
      min-height: 450px;
    };
    @include media(tel){
      min-height: inherit;
    };
  }
}
.press-single__thumb{
  float: left;
  @include media(tel){
    width: 100%;
    float: none;
    text-align: center;
    padding-right: 0;
  };
  padding-right: 40/1200*100%;
  background-color: #fff;
  margin-bottom: 15px;
  max-width: 667px;

}
.press-single__title{
  color: #212121;
  font-size: 38px;
  margin-top: 0;
  border-bottom: 1px solid $theme-color;
  padding-bottom: 24/1200*100%;
  margin-bottom: 21px;
}
.press__soc{
  margin-bottom: 28/1200*100%;
  .social{
    margin-top: 3px;
  }
}
