@import "_includs/_mixins.scss";
.other-news{
  margin-bottom: 38px;
  .s-header{
    margin-bottom: 76px;
    padding-top: 0;
  }
}
.other-news-slider__item{
  @include media(tel){
    text-align: center;
  };
}
