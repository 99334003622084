@import "_includs/_mixins.scss";

.mfp-figure{
  position: relative;
  figure{
    margin: 0;
  }
}

.mfp-bg {
    z-index: 1010;
    overflow: hidden;
    background: #000;
    opacity: .8;

}

.mfp-wrap{
  z-index: 1010;

}

.mfp-figure:after {
    top: 40px;
    bottom: 40px;
    right: 0;
    z-index: -1;
    // background: #000;
}
.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg{
  transition: all 0.3s ease-out;
}
.mfp-image-holder button.mfp-close{
  @include size(39px, 41px);
  padding: 0;
  font-size: 0;
  color: $theme-color;
  &::after,
  &::before{
    content: '';
    position: absolute;
    transform-origin: 50% 50%;
    height: 2px;
    background-color: currentColor;
    width: 45px;
    top: 50%;
    left: 50%;
    transition: all 0.3s;
  }
  &::after{
    transform: translate(-50%,-50%) rotate(45deg);
  }
  &::before{
      transform: translate(-50%,-50%) rotate(-45deg);
  }
}

.modal{
  position: relative;
  font-size: 18px;
  margin: auto;
  max-width: 1170px;
  &--order-similar{
    max-width: 917px;
  }
  &--extend-subscr{
    max-width: 974px;
  }
  // &--order-individual{
  //
  // }

  &--register{
    overflow: hidden;
  }
  &--ask-question{
    max-width: 515px;
  }
  &--massage{
    max-width: 400px;
    text-align: center;

    .modal__body{
      padding-left: 15px;
      padding-right: 15px;
    }
    .massage-txt{
      color: #fff;
      font-size: 20px;
    }
    .btn{
      font-size: 19px;
      padding: (50-19)/2 + px 27px;
    }
    .submit-wrap{
      padding-top: 30px;
    }
  }

  &--order-prepear{


    // max-width: 800px;
    // .submit-wrap{
    //   padding-top: 25px;
    // }

    // .btn{
    //   padding: .63158em 1.47368em;
    // }

    // .modal__body{
    //   padding-left: 2.82051%;
    //   padding-right: 2.82051%;
    // }
  }
}

.modal--order-prepear{
  textarea{
    vertical-align: bottom;
  }
}
.modal__header{
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  font-family: 'Univers';
  font-size: em(34,18);
  @include media(tel){
    font-size: em(28,18);
  };
  font-weight: 400;
  background-color: #28acbe;
  padding: em(18,34) 0;
  .modal--ask-question &{
    background-color: #1999aa;
  }
  .modal--use-serv &{
    height: 70px;
  }
}
.modal__title{
  .modal--ask-question &{
    font-size: 25px;
    font-weight: 700;
  }
  .modal--massage &{
    font-size: 22px;
    font-weight: 700;
  }
}
.modal__body{
  background-color: #178b9b;
  padding: 43/1170*100% 0 em(30,18);
  color: #3b3b3b;
   + button.mfp-close{
     font-size: 18px;
     right: em(15,18);
     top: em(15,18);

    @include size(39px, 41px);
    padding: 0;
    text-indent: -9999px;
    // color: $theme-color;
    &::after,
    &::before{
      content: '';
      position: absolute;
      transform-origin: 50% 50%;
      height: 2px;
      background-color: currentColor;
      width: 53px;
      top: 50%;
      left: 50%;
      transition: all 0.3s;
    }
    &::after{
      transform: translate(-50%,-50%) rotate(45deg);
    }
    &::before{
        transform: translate(-50%,-50%) rotate(-45deg);
    }
  }

  .modal--order-individual &{
    padding-left: 23/1170*100%;
    padding-right: 23/1170*100%;
  }
  .modal--extend-subscr &{
    padding-left: 59/974*100%;
    padding-right: 59/974*100%;
  }
  .modal--register &{
    padding-left: 33/1170*100%;
    padding-right: 33/1170*100%;
  }
  .modal--ask-question &{
    padding-left: 68/515*100%;
    padding-right: 68/515*100%;
    padding-top: 30px;
    background-color: #1999aa;
  }
  .modal--enter-base &{
    padding-left: 114/1170*100%;
    padding-right: 114/1170*100%;
    // padding-top: 30px;
    // background-color: #1999aa;
  }
  .modal--use-serv &{
    padding-left: 46/1170*100%;
    padding-right: 46/1170*100%;
  }
}

.order-similar{
  max-width: 800px;
  padding: 0 10px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .submit-wrap{
    margin-top: em(32,18);
    margin-bottom: em(22,18);
  }

  input,textarea,select{
    // border: none;
    font-size: em(16,18);
  }
  textarea{
    height: em(154,16);
  }
  select{
    border-radius: 0;
  }
  .btn{
    font-size: 32px;
  }
}
.submit-wrap{
  width: 100%;
  text-align: center;
}
.order-similar__col{
  width: calc(50% - 21px);
  @include media(tel){
    width: 100%;
    padding-bottom: 5px;
  };
  .form-group{
    margin-bottom: 5px;
    padding-top: 5px;
    &:first-of-type{
      padding-top: 0;
    }
  }
}
.order-individual{
  display: flex;
  @include media(tel){
    flex-direction: column;
  };
}

.order-individual__left-col{
  width: 404/(1170-46)*100%;
  padding-right: 34/1170*100%;
  @include media(tel){
    width: 100%;
    padding-right: 0;
    margin-bottom: 20px;
  };
  // input,textarea{
  //   border: none;
  // }
  // textarea{
  //   max-height: 71px;
  // }
}
.form-group{
  margin-bottom: 5px;
  padding-top: 5px;
  &:first-of-type{
    padding-top: 0;
  }
}
.order-individual__right-col{
  width: 100%;
  .submit-wrap{
    margin-top: 25px;
    text-align: left;
  }
  textarea{
    min-height: 147px;
  }
}

.check-list{
  counter-reset: check-list;
  font-size: 18px;
  font-weight: 700;
  list-style: none;
  margin: 0;
  padding: 0;
}
.check-list__item{
  color: #1b1b1b;
  margin-bottom: 5px;
}

.check-sub-list__item{
  color: #eafbfd;
  margin-bottom: 8px;
}
.check-sub-list{
  padding-bottom: 4px;
  padding-left: 31px;
  margin-top: 8px;
}


.first-level-label{


}

.check-label{
  display: block;
  position: relative;
  counter-increment: check-list;
  padding-left: 31px;
  line-height: (21/18);

  .order-individual &{
    &::before{
      content: counters(check-list,".") ". ";
    }
  }
  &::after{
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    @include size(18px, 22px);
    background-color: #fff;
    padding: 1px 3px 2px 3px;
    // @include sprite($check);
    // @include sprite-image($check);
    // @include sprite-position($check);
    background-position: -50px -209px;

  }
}

.check{
  position: absolute;
  opacity: 0;
  &:checked{
    +.check-label::after{
      @include sprite-position($check);
      @include sprite-image($check);
      background-position: -278px -216px !important;
    }
  }
}

.register{
  display: flex;
  justify-content: space-between;
  @include media(tel){
    flex-wrap: wrap;
  };
}
.register__left-col{
  width: 370/(1170-33*2)*100%;
  @include media(tel){
    width: 100%;
  };
}
.register__right-col{
    width: 670/(1170-33*2)*100%;
    display: flex;

    @include media(tablet){
      flex-wrap: wrap;
    };
    @include media(tel){
      width: 100%;
    };
}
.status{
  color: #fff;
  font-weight: 700;
}
.status__heading{
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding-top: 14px;
  margin-bottom: 10px;
}
.register__title{
  text-transform: uppercase;
  color: #1b1b1b;
  font-family: 'Univers';
}
.rss{

}
.rss__label{
  font-size: 18px;
  &::after{
    top: 1px;
  }
}
.radio-list{
  padding: 0;
  list-style: none;
  margin: 0;
  color: #fff;
  font-weight: 700;
  li{
    margin-bottom: 7px;
  }
}
.status__radio-list{
  margin-bottom: 25px;
}

.status-company{
  font-size: 16px;
  color: #3b3b3b;
  font-weight: 400;

  &.hidden{
    display: none;
  }
}

.conditions{
  padding-top: 20px;
  margin-bottom: 20px;
}
.conditions__label{
  line-height: (21/18);
  &::after{
    top: 4px;
  }
  a{
    &:hover{
      text-decoration: underline;
    }
  }
}

.col-inner{
  width: 410/670*100%;
  @include media(tablet){
      width: 100%;

      &:first-of-type{
        margin-bottom: 20px;
      }
  };
}
.subscribe-type{
  width: 240px;
  flex-shrink: 0;
  margin-right: 20px;
  @include media(tablet){
      width: 100%;
      margin-right: 0;
  };
}
.subscribe-type{

}
.subscribe-type__title{
  white-space: nowrap;
  display: block;
  color: #1b1b1b;
  font-family: 'Univers';
  font-weight: 400;
  font-size: 34px;
  margin-bottom: 17px;
  @include media(tablet){
      text-align: center;
  };
}
.subscribe-type__radio-list{
  padding-bottom: 14px;
  white-space: nowrap;
}

.multi-subscribe{
  // &.hidden{
  //   display: none;
  // }
}
.period{
  padding-top: 15px;
}
.small-title{
  font-style: italic;
  font-size: 18px;
  color: #eaf8fa;
  display: block;
  margin-bottom: 14px;
}
.totals-subscribe{
  margin-top: 15px;
  span{
    display: block;
    color: #eaf8fa;
  }

  // &.hidden{
  //   display: none;
  // }
}
.presentation-link{
  text-align: center;
  margin-bottom: 30px;
  .btn{
    font-size: 19px;
    padding: em(((43-19)/2),19) em(28,19);
  }
  @include media(tablet){
      margin-bottom: 20px;
  };
}
.subscribe-price{
  text-align: center;
  margin-bottom: 78px;
  @include media(tablet){
      margin-bottom: 20px;
  };
}
.total-subscribe-price{
  display: block;
  font-size: 73px;
  color: #fff;
  font-family: 'Univers';
  margin-bottom: 9px;
}
.block-title{
  display: block;
  text-transform: uppercase;
  color: #1b1b1b;
  font-size: 23px;
  font-family: 'Univers';
}
.payment-methods{
  text-align: center;
  padding-bottom: 10px;
}
.payment-methods__list{
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
  li{
    position: relative;
    width: calc(33.3333333% - 4px);
    @include media(tablet){
        width: auto;
    };
    @include media-custom(440px){
        width: calc(33.3333333% - 4px);
    };

  }

  .radio-hidden{
    opacity: 1;
    left: 50%;
    top: 75px;
    transform: translateX(-50%);
    &:checked + .payment-methods-label{
      box-shadow: 0 0 0 3px #2e3f42;
    }
  }
}
.payment-methods-label{
  box-shadow: 0 0 0 0px #2e3f42;
  display: flex;
  margin-bottom: 15px;
  transition: all 0.3s;
}

/////////////////////////////////////////////////////////////////////////////ask-question
.ask-question{
  .btn{
    font-size: 19px;
    padding: (50-19)/2 + px 27px;
  }
  .submit-wrap{
    padding-top: 40px;
  }
}

////////////////////////////////////////////////////////////////////////////////enter-base
.enter-base{
  display: flex;
  justify-content: space-between;
  @include media(tel){
    flex-wrap: wrap;
  };

  .submit-wrap{
    padding-top: 36px;
    text-align: left;
    @include media(tel){
      text-align: center;
    };
  }
}
.enter-base__col{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include media(tel){
    width: 100%;
    margin-bottom: 36px;
  };
  &:first-child{
    width: 370/(1170-114*2)*100%;
    margin-right: 67/(1170-114*2)*100%;
    @include media(tel){
      width: 100%;
      margin-right: 0;
    };
  }
}
.enter-base__advantages{
  padding: 0;
  margin: 0;
  list-style:none;
  color: #eaf8fa;
  font-size: 18px;
  font-weight: 700;
  li{
    position: relative;
    padding-left: 27px;
    margin-bottom: 8px;
    &::after,
    &::before{
      content: '';
      position: absolute;
      color: #1b1b1b;
    }
    &::after{
      @include size(8px, 12px);
      background-color: currentColor;
      left: 0;
      top: 5px;
    }
    &::before{
      @include size(0);
      border-left: 10px solid currentColor;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      left: 9px;
      top: 1px;
    }
  }
}


///////////////////////////////////////////////////////////////use-serv
.use-serv{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  color: #fff;
  font-family: 'Univers';
  text-align: center;
}
.use-serv__title{
  font-size: 34px;
  @include media(tel){
    font-size: 25px;
  };
  width: 100%;
  padding-top: 6px;
  margin-bottom: 61/(1170-46*2)*100%;
}
.use-serv__item{
  position: relative;
  overflow: hidden;
  height: 312px;
  width: calc(50% - 20px);
  @include media(tel){
    width: 100%;
    margin-bottom: 20px;
    height: 200px;
  };
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  &:hover{
    box-shadow: 0 0 0 5px rgba($theme-color, 0.8) inset;
    .use-serv__over{
      background-color: rgba(#000, .3);
    }
  }
}
.use-serv__over{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(#000, .5);
  font-size: 32px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 90/530*100%;
  transition: all 0.3s;

  span{
    position: relative;
    @include underline(0.3s);
    &:hover{
      @include underline-hovered;
    }
  }
}


.extend-subscr{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .submit-wrap{
    margin-top: 40px;
    .btn{
      background-color: #1a2c2e;
    }
  }

}
.extend-subscr__left-col{
  max-width: 326px;

  .ch-type{
    margin-top: 45px;
    text-align: center;
    .btn{
      background-color: #1a2c2e;
      font-size: 19px;
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }
}
.col-title{
  font-size: 34px;
  font-family: 'Univers';
  color: #1b1b1b;
  margin-bottom: 37px;
}
.extend-subscr__right-col{
  max-width: 411px;
  .payment-methods{
    margin-top: 53px;
  }
}
.extend-subscr__radio-list{
  color: #eaf8fa;
  font-size: 18px;
  font-weight: 700;
  list-style: none;
  margin: 0;
  padding: 0;
  li{
    margin-bottom: 8px;
  }

}
.extend-subscr__price{
  display: flex;
  align-items: center;
  span{
    text-transform: uppercase;
    font-family: 'Univers';
    font-size: 23px;
    color: #1b1b1b;
    margin-right: 25px;
  }
  strong{
    color: #fff;
    font-size: 73px;
    font-weight: normal;
  }
}

.payment-methods__title{
  text-transform: uppercase;
  font-family: 'Univers';
  font-size: 23px;
  color: #1b1b1b;
  margin-bottom: 37px;

}

.modal-confirm{
  position: fixed;
  top: 50%;
  left: 50%;
  width: 400px;
  text-align: center;
  padding: 15px;
  background-color: $theme-color;
  max-width: 100%;
  color: #fff;
  font-family: 'Univers';
  z-index: 2000;
  transform: translate(-50%,-50%) perspective( 600px ) rotateX( 0deg );
  opacity: 1;
  visibility: visible;
  transition: all 0.4s;
  &.hidden{
    transform: translate(-50%,-50%) perspective( 600px ) rotateX( 20deg );
    opacity: 0;
    visibility: hidden;
  }
}
.modal-confirm__over{
  position: fixed;
  top: -1000px;
  left: -1000px;
  right: -1000px;
  bottom: -1000px;
  overflow: hidden;
  background: #000;
  opacity: .8;
  z-index: 1950;
  opacity: 0.8;
  visibility: visible;
  transition: all 0.4s;
  &.hidden{
    opacity: 0;
    visibility: hidden;
  }
}
.modal-confirm__text{
  font-size: 20px;
  margin-bottom: 15px;
}
.modal-confirm__buttons{
  .btn:first-child{
    margin-right: 10px;
  }
}


.my-mfp-slide-bottom .modal {
        opacity: 0;
        -webkit-transition: all 0.2s ease-out;
        -moz-transition: all 0.2s ease-out;
        -o-transition: all 0.2s ease-out;
        transition: all 0.2s ease-out;

        -webkit-transform: translateY(-20px) perspective( 600px ) rotateX( 10deg );
        -moz-transform: translateY(-20px) perspective( 600px ) rotateX( 10deg );
        -ms-transform: translateY(-20px) perspective( 600px ) rotateX( 10deg );
        -o-transform: translateY(-20px) perspective( 600px ) rotateX( 10deg );
        transform: translateY(-20px) perspective( 600px ) rotateX( 10deg );

      }

      /* animate in */
      .my-mfp-slide-bottom.mfp-ready .modal {
        opacity: 1;
        -webkit-transform: translateY(0) perspective( 600px ) rotateX( 0 );
        -moz-transform: translateY(0) perspective( 600px ) rotateX( 0 );
        -ms-transform: translateY(0) perspective( 600px ) rotateX( 0 );
        -o-transform: translateY(0) perspective( 600px ) rotateX( 0 );
        transform: translateY(0) perspective( 600px ) rotateX( 0 );
      }

      /* animate out */
      .my-mfp-slide-bottom.mfp-removing .modal {
        opacity: 0;

        -webkit-transform: translateY(-10px) perspective( 600px ) rotateX( 10deg );
        -moz-transform: translateY(-10px) perspective( 600px ) rotateX( 10deg );
        -ms-transform: translateY(-10px) perspective( 600px ) rotateX( 10deg );
        -o-transform: translateY(-10px) perspective( 600px ) rotateX( 10deg );
        transform: translateY(-10px) perspective( 600px ) rotateX( 10deg );
      }

      /* Dark overlay, start state */
      .my-mfp-slide-bottom.mfp-bg {
        opacity: 0;

        -webkit-transition: opacity 0.3s ease-out;
        -moz-transition: opacity 0.3s ease-out;
        -o-transition: opacity 0.3s ease-out;
        transition: opacity 0.3s ease-out;
      }
      /* animate in */
      .my-mfp-slide-bottom.mfp-ready.mfp-bg {
        opacity: 0.8;
      }
      /* animate out */
      .my-mfp-slide-bottom.mfp-removing.mfp-bg {
        opacity: 0;
      }


      .modal--about{
        max-width: 1000px;
        .wrp{
          color: #fff;
          padding: 0 50px;
          display: flex;
          justify-content: space-between;

          @include media(tel){
            flex-direction: column;
            padding: 0 15px;
          };
        }
        .col{
          width: calc(50% - 15px);
          @include media(tel){

            width: auto;
          };
        }
        .photo{
          text-align: center;
            img{
              width: 100%;
              display: inline-block;
            }

        }
        .ttl{
          font-family: 'Univers';
          font-size: 35px;
        }
        .pos{
          margin-top: 10px;
        }
        .txt{

          p{
            font-size: 17px;
          }
        }
  }
