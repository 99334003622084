@import "_components/_includs/_mixins.scss";
.contacts{
  position: relative;
  padding-bottom: 114px;
  padding-top: 20px;
  @include media(tablet){
    background-attachment: fixed;
  };
  &::after{
    content: '';
    @include coverlayer;
    background-color: rgba(#fff,0.8);
    z-index: 0;
  }
  .container{
    flex-wrap: wrap;
    z-index: 10;
  }
}
.contacts__item{
  width: calc(50% - 26px);
  @include media(tel){
    width: 100%;
    margin-bottom: 15px;
  };

  &:nth-of-type(2){
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: 'Univers';
    font-weight: 400;
    font-size: 28px;
    color: #212121;
    line-height: (32/28);
  }
  .adress{
    margin-bottom: 10px;
  }
  .info{
    margin-bottom: 64px;
    @include media(tel){
      margin-bottom: 0;
    };
    a[href*='mail']{
      color: $theme-color;
    }
  }
}
.contacts__form{
  color: #5b5b5b;
  font-size: 17px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .form-group{
    margin-bottom: 12px;

    &:first-of-type,
    &:nth-of-type(2){
      width: calc(50% - 12px);
      @include media(tel){
        width: 100%;
      };
    }
    &:nth-of-type(2){
      padding-top: 0;
      @include media(tel){
        margin-top: 12px;
      };
    }
    &:last-of-type{
      width: 100%;
      margin-top: 12px;
    }
  }
  .btn{
    margin-top: 12px;
    &:hover{
      border-color: $theme-color;
    }
  }
}
