@import "_includs/_mixins.scss";

.share{
  margin-top: 30px;

  .shop &{
    margin-top: 36px;
    margin-bottom: 35px;
  }
  .press-center &{
    width: 100%;
    margin: 0;
  }
  // .ya-share2__container_size_m .ya-share2__item_service_gplus .ya-share2__icon,
  // .ya-share2__container_size_m .ya-share2__item_service_vkontakte .ya-share2__icon,
  .ya-share2__container_size_m .ya-share2__item_service_linkedin .ya-share2__icon,
  .ya-share2__container_size_m .ya-share2__item_service_twitter .ya-share2__icon,
  .ya-share2__container_size_m .ya-share2__item_service_facebook .ya-share2__icon{
        background-image: url(../img/images/sprite.png)!important;
        width: 29px!important;
        height: 29px!important;
        display: block!important;
        transition: none!important;
  }
  // .ya-share2__container_size_m .ya-share2__item_service_gplus .ya-share2__badge,
  // .ya-share2__container_size_m .ya-share2__item_service_vkontakte .ya-share2__badge,
  .ya-share2__container_size_m .ya-share2__item_service_linkedin .ya-share2__badge,
  .ya-share2__container_size_m .ya-share2__item_service_twitter .ya-share2__badge,
  .ya-share2__container_size_m .ya-share2__item_service_facebook .ya-share2__badge{
      background-color: transparent !important;
  }
  .ya-share2__container_size_m .ya-share2__item_service_twitter .ya-share2__icon{
    // background-position: -203px -232px !important;
    @include sprite-position($tw);

    &:hover{
          // background-position: -58px -232px !important;
          @include sprite-position($tw_active);
    }
  }
  // .ya-share2__container_size_m .ya-share2__item_service_gplus .ya-share2__icon{
  //   background-position: -134px -180px !important;
  //
  //   &:hover{
  //         background-position: 0 -271px !important;
  //   }
  // }
  .ya-share2__container_size_m .ya-share2__item_service_facebook .ya-share2__icon{
    // background-position: -192px -180px !important;
    @include sprite-position($fb);

    &:hover{
          // background-position: -116px -271px !important;
            @include sprite-position($fb_active);
    }
  }
  // .ya-share2__container_size_m .ya-share2__item_service_vkontakte .ya-share2__icon{
  //   background-position: -163px -180px !important;
  //
  //   &:hover{
  //         background-position: -58px -271px !important;
  //   }
  // }
  .ya-share2__container_size_m .ya-share2__item_service_linkedin .ya-share2__icon{
    // background-position: -134px -180px !important;
      @include sprite-position($ld);

    &:hover{
          // background-position: -163px -180px !important;
            @include sprite-position($ld_active);
    }
  }
}

.share__wrap{
  display: flex;
  align-items: center;

  .social{
    margin-top: 0;
    margin-left: 5px;
    margin-bottom: 3px;
  }
}
