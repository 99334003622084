@import "_components/_includs/_mixins.scss";
.vacancy{

}
.vacancy__wrap{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}
.vacancy__item{
  width: 380/1200*100%;
  @include media(tablet){
    width: calc(50% - 15px);

  };
  @include media(tel){
    width: 100%;
  };
  background-color: #efefef;
  margin-bottom: 32px;
  .holder{
    height: 295px;
    padding-top: 38px;
  }
  .holder__title{
    font-family: 'Univers';
    font-size: 24px;
    margin-bottom: 25px;
  }
}
.vacancy__image{
  margin: 0;
  img{
    width: 142px;
  }
}
.vacancy__name{
  margin-top: 17px;
  color: #34646c;
  font-size: 28px;
  font-family: 'Univers';
  padding: 0 50/380*100%;
}
