@import "_includs/_mixins.scss";

$vertical-padding: 15.5;
$horisontal-padding: 32;
$font-size-small: 19;
$font-size-big: 24;

@mixin btn-active-after {
  @content;
  &::after {
      width: 100%;
  }
}
@mixin after-bg($bg: $theme_color, $l: 0, $b:0,$t:0,$r:auto) {
    &::after {
        content: '';
        position: absolute;
        top: $t;
        left: $l;
        right: $r;
        bottom: $b;
        width: 0;
        z-index: -1;
        background-color: $bg;
        border-radius: rem(4);
        transition: all 0.3s;
        // box-shadow: 0 0 0 0 transparent inset;
        @content;
    }
}
.btn {
    position: relative;
    display: inline-block;
    vertical-align: top;
    user-select: none;
    touch-action: manipulation;
    text-align: center;
    text-transform: uppercase;
    font-family: 'Univers';
    font-weight: 500;
    cursor: pointer;
    border-radius: rem(4);
    border-width: 1px;
    border-style: solid;

    // min-width: rem(173);
    z-index: 1;
    // @include text_ellipsis;

    &.hidden{
      display: none;
    }

    &--change-sections{
      padding: 0;
      margin: 0;
      border: none;
      background: none;
      color: #ace9f2;
      font-size: 20px;
      text-transform: uppercase;
      &:hover{
        text-decoration: underline;
      }
    }



    &--cancel-edit{
      border: none;
      background: none;
      padding: 0;
      font-size: 17px;
      font-family: 'Calibri';
      color: #9f0f0f;
      text-transform: none;
      margin-left: 18px;
      &.hidden{
        display: none;
      }
      .txt{
        display: inline-block;
        vertical-align: top;
            padding-top: 2px;
      }
      .pl{
        display: inline-block;
        vertical-align: top;
        position: relative;
        @include size(24px);
        &::before,
        &::after{
          content: '';
          position: absolute;
          height: 2px;
          background-color: currentColor;
          width: 30px;
          top: 50%;
          left: 50%;
          transition: all .3s;
        }
        &::after{
          transform: translate(-50%,-50%) rotate(45deg);
        }
        &::before{
          transform: translate(-50%,-50%) rotate(-45deg);
        }
      }

      &:hover{
        .txt{
          text-decoration: underline;
        }

      }
    }

    &--user-info{
      font-size: 19px;
      padding: 7px 6px 7px 29px;
      transition: all 0.3s;
      width: 185px;
      height: 51px;
      white-space: nowrap;
      .txt{
        display: inline-block;
        vertical-align: top;
        padding-top: 8px;
        margin-right: 30px;
      }
      &:hover{
        border-color: $theme-color;
        .txt{
          text-decoration: underline;
        }
      }
      .icon{
        display: inline-block;
        vertical-align: top;
      }
    }

    &--change-info{
      background-color: #fff;
      color: $theme-color;


      .icon{
        &--pencil{
          @include sprite($pencil);
        }
      }
    }

    &--save-info{
      background-color: $theme-color;
      padding: 10px 16px 10px 26px;
      .txt{
        color: #fff;
        padding-top: 5px;
      }
      .icon{

        &--pencil{
          @include sprite($save);
        }
      }
    }

    &--clear-base-search{
      background: none;
      color: #fff;
      font-size: 19px;
      border: none;
      padding: 0;
      margin: 0;
      display: table;
      span{
        display: table-cell;
        vertical-align: middle;

      }
      .txt{
      padding-right: 13px;
      }
      &:hover{
        .txt{
          text-decoration: underline;
        }
      }

      .plus{
        color: #1d1d1b;
        @extend .btn--close-ask;
      }
    }

    &--submit-modal{
      background-color: #2e3f42;
      color: #fff;
      padding: em(16.5,40) em(39,32);
      border:none;
      @include after-bg(#fff);

      @include state{
        @include btn-active-after{
          color: $theme-color;
        }
      }
      &-bigger{
        font-size: 19px;
        padding: em(((42-19)/2),19) em(27,19);
      }
      &-full{
        width: 100%;
        max-width: 373px;
        font-size: 30px;
        padding: em(((73-30)/2),30) em(27,30);
      }
    }

    &:focus{
      outline: none;
    }

    &--share{
      width: 29px;
      padding: 0;
      background: none;
      border: none;
      vertical-align: bottom;
      svg{
        fill: #909090;
        transition: all 0.3s;
      }
      &:hover{
        // color: $theme-color;
        svg{
          fill: $theme-color;
        }
      }
    }

    &--default {
        border-color: #bfbfbf;
        font-size: rem($font-size-small);
        background: transparent;
        padding: rem($vertical-padding) rem($horisontal-padding);
        white-space: nowrap;

        @include after-bg;

        @include state{
          @include btn-active-after{
            color: #fff;
          }
        }

    }

    &--dark {
        font-size: rem($font-size-big);
        padding: rem($vertical-padding - 2.5) rem(58.295);
        color: #212121;
        overflow: hidden;
        @include after-bg;

        @include state{
          @include btn-active-after{
            color: #fff;

          }
        }

    }

    &--white{
      font-size: 24px;
      border-color: #fff;
      color: #fff;
      padding: ((51-24)/2+px) 34px;
    }

    &--accent{
      padding: rem(10.5) rem(23);
      background-color: $theme_color;
      color: #fff;
      border-color: $theme_color;
      font-size: $font-size-small;
      @include after-bg(#fff,  0%, 0, 0);

      @include state{
        color: $theme_color;
        border-color: #fff;
          background-color: #fff;
        &::after{
          left: 0;
          // right: 0;
          width: 100%;
        }
      }

    }

    &--menu{
      @include size(21px, 26px);
      border: none;
      background-color: transparent;
      padding: 0;

      .lines{
        position: absolute;
        left: 0;
        width: 100%;
        height: 1px;
        top: 10px;
        background: currentColor;
        transition: all 0.3s;
      }
      &::after,
      &::before{
        position: absolute;
        left: 0;
        content: '';
        background-color: currentColor;
        height: 1px;
        width: 100%;
        transform-origin: 50% 50%;
        transform: translate(0,0);
        transition: all 0.3s;
      }
      &::after{
        bottom: 0;
      }
      &::before{
        top: 0;
      }

      &:hover{
        &::after{
          transform: translateY(-2px);
        }
        &::before{
          transform: translateY(2px);
        }
      }

      &.opened{
        .lines{
          transform: scale(0);
        }
        &::after{

          transform: translateY(-10px) rotate(45deg);
        }
        &::before{
          transform: translateY(10px) rotate(-45deg);
        }
      }
    }

    &--hidden-nav{
      position: absolute;
      top: (41/$n-height)*100 + vh;
      right: (48/$n-width)*100 + vw;
      color: #fff;
      @include size(32px, 43px);
      transition: all 0.3s;
      .lines{
        top: 15px;
      }
      &::after,
      &::before{
        transform-origin: 67% 50%;
        transition: all 0.6s;
      }
      &:hover{
        transform: scale(0.9);
      }
    }

    &--search-draw{
      @include size(rem(21), rem(21));
      border: none;
      color: currentColor;
      background-color: transparent;
      @include pseudo-both{
        position: absolute;
        transition: all 0.3s;
      }
      &::before{
        width: 16px;
        height: 16px;
        border: 1px solid currentColor;
        border-radius: 100%;
        top: 0;
        left: 0;
      }
      &::after{
        @include size(1px, 11px);
        background: currentColor;
        bottom: 0;
        right: 0;
        transform-origin: right bottom;
        transform: rotate(45deg);
      }
    }

    &--submit-search{
      @include size(rem(39), rem(49));
      border-radius: 4px;
      background-color: rgba(#00648b,0.33);
      transition: all 0.3s;
      color: #fff;

      &::before{
        top: 8px;
        left: 13px;
      }
      &::after{
        top: 28px;
        left: 23px;
      }

      &:hover{
        background-color: rgba(#00648b,0.5);
      }
    }

    &--white{
      color: #fff;
    }

    &--enter{
      // @include size(rem(23), rem(20));
      color: #000;
      border: none;
      font-size: rem(17);
      background: none;
      padding: 0;
      margin: 0 10px;

      .enter-lines{
        display: block;
        @include sprite($enter-arrow-black);
      }

      &:hover{
        color: $theme_color;
      }
    }

    &--down{
      display: inline-block;
      position: relative;
      background-color: transparent;
      border: none;
      border-radius: 0;
      padding: 0;
      @include sprite($down);

      &::after{
        content: '';
        position: absolute;
        @include sprite($down);
        left: 0;
        bottom: -8px;
        transition: transform 0.3s;
      }
      &:hover{
        &::after{
          transform: translateY(5px);
        }
      }
    }

    &--head{
      display: inline-flex;
      align-items: center;
      border: none;
      padding: rem(6) rem(9) rem(8) rem(8);
      background-color: rgba(#000, 0.61);
      border-radius: 0;
      color: #fff;

      &:hover{
        background-color: #000;
      }
      .enter-lines{
        @include sprite($enter-arrow);
      }
    }

    &--close{
      border: none;
      background: none;
      padding: 0;
      color: #fff;
      @include size(50px);
      .plus{
        margin: 0;
        transform: rotate(45deg);
        @include size(50px);

        &::after {
            @include size(rem(50), 1px);
        }
        &::before {
            @include size(1px, rem(50));
        }
      }

      @include state{
        color: red;
      }
    }

    &--ask{
      border-radius: 0;
      background-color: lighten(desaturate(adjust-hue($theme-color, -0.6259), 3.7736), 0.5882);
      color: #fff;
      font-size: 19px;
      padding: ((43-19)/2 + px) 26px;
      position: fixed;
      right: 7px;
      bottom: 0;
      border: none;
      z-index: 110;

      #register &{
        // position: absolute;
        // bottom: 0;
        // right: 0;
        // background-color: darken($theme-color,10%);

        // &:hover{
          // background-color: darken($theme-color,15%);

        // }
        position: relative;
        border-radius: 5px;
        font-size: 30px;
        padding: .71667em .9em;
        background-color: #2e3f42;
      }
    }

    &--ask-modal{
      @extend .btn--close-ask;
    }

    &--close-ask{
      position: relative;
      @include size(34px, 32px);
      background: none;
      border: none;

      &::after,
      &::before{
        content: '';
        position: absolute;
        transform-origin: 50% 50%;
        height: 2px;
        background-color: currentColor;
        width: 45px;
        top: 50%;
        left: 50%;
        transition: all 0.3s;
      }
      &::after{
        transform: translate(-50%,-50%) rotate(45deg);
      }
      &::before{
          transform: translate(-50%,-50%) rotate(-45deg);
      }

      &:hover{
        &::after{
          transform: translate(-50%,-50%) rotate(45deg) scaleX(0.9);
        }
        &::before{
          transform: translate(-50%,-50%) rotate(-45deg) scaleX(0.9);
        }
      }
    }



    &--toggle-serv{
      border-radius: 0;
      // text-align: left;
      background: none;
      border: none;
      padding: 0;
      position: fixed;
      left: 0;
      bottom: 55vh;
      z-index: 100;
      @include media(tel){
        bottom: 0;
      };

      polyline{
        // fill: none;
        // background-color: red;
        transition: all 0.3s;
      }
      // font-size: 19px;
      // padding-left: 17px;
      // padding-right: 14px;
      &::after{
        // border-radius: 0;
        // transition: none;
        display: none;
      }
      &::before{
        // content: '';
        // position: absolute;
        // top: -1px;
        // right: -28px;
        // width: 0;
        // height: 0;
        // border-left:27px solid $theme-color;
        // border-top: 21px solid transparent;
        // border-bottom: 21px solid transparent;
        display: none;
      }
      &:hover{
        background: none;
        // background-color: $theme-color;
        // border-color: $theme-color;
        // color: #fff;
        polyline{
          fill: #fff;
          stroke: $theme-color;
        }

        text{
          fill: $theme-color;
        }
        &::after{
          display: none;
          // background-color: $theme-color;
        }
      }
    }
}



.a-wrap{
  margin-right: rem(19);
}
.a-text{
  margin-bottom: rem(5);
    font-size: rem(21);
    display: block;
    text-align: left;
}
.a-count{
    font-size: rem(32);
    font-weight: 400;
    display: block;
    text-align: left;
}

.plus {
    display: inline-block;
    vertical-align: baseline;
    margin-left: rem(3);
    @include size(rem(15));
    @include absolut_pseudo {
        background-color: currentColor;
        // transition: all 0.1s;
    }

    &::after {
        @include size(rem(15), 1px);
        // @include center_block;
        right: calc(50% - 0.5px);
        top: 0;
    }
    &::before {
        @include size(1px, rem(15));
        // @include center_block;
        right: 0;
        top: calc(50% - 0.5px);
    }
}
