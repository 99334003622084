@import "_includs/_mixins.scss";

*,
*::after,
*::before {
    box-sizing: border-box;
}

body,
html {
    margin: 0;
    padding: 0;
    font-size: $root-font-size + px;
    font-weight: normal;
    min-width: 320px;
    height: 100%;
    line-height: 1;
    color: $font-color;
    font-family: $font-family;
    background: $background-main;
    @include font_smoothing;

}
a {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
}

polyline{
  transition: all 0.3s;
}
@for $i from 1 through 6{
  h#{$i}{
    font-family: 'Univers';
    font-weight: normal;
    font-size: inherit;
  }
}
img{
  max-width: 100%;
  height: auto;
}

.icon{
  display: inline-block;
}
p{
  font-size: 19px;
  line-height: (22/19);
}

.not-logged{
  text-align: center;

  a{
    color: $theme-color;
    position: relative;
    @include underline(0.3s);
    &:hover{
      @include underline-hovered
    };
  }
}
.base-info{
  text-align: left;

  ul{
    display: inline-block;
    width: 49%;
    vertical-align: top;
    @include media(tel){
      width: 100%;
    };
  }
}
.base-info-titl{
  text-align: center;
  font-size: 30px;
}
.not-logged-titl{
  font-family: 'Univers';
  text-transform: uppercase;
  color: #212121;
  font-size: 2.76471rem;
  margin-bottom: 1em;
  margin-top: 1em;
}
.not-logged-txt{
  color: #212121;
  font-size: 1.76471rem;
  margin-bottom: 1em;

}
