@import "_includs/_mixins.scss";

$title-color: #212121;
$header-avarage-padding-top: (65 + 61 + 55 + 40 + 40 + 40) / 6;
$title-padding: rem(5.5);

.s-header{
  padding: rem($header-avarage-padding-top) 0 0;
  text-align: center;
  text-transform: uppercase;
  color: $title-color;
  font-size: rem(47);
  width: 100%;
  @include media(tablet){
    font-size: 35px;
  };
}

.title{
  display: inline-block;
  margin: 0;

  .clients &,
  .press &,
  .mp-services &,
  .mp-projects &,
  .mp-about &{

    &:hover{
      color: $theme-color;
      .line{

        &::before,
        &::after{
          transform: scaleX(0.8);
        }
      }
    }
  }
}
.line{
  display: block;
  width: 100%;
  height: 1px;

  &--top{
    margin-bottom: $title-padding;
  }
  &--bottom{
    margin-top: $title-padding;
  }
  @include absolut_pseudo{
    background-color: $theme_color;
    top: 0;
    bottom: 0;
    transition: transform 0.3s;

    width: 39.5%;
  }
  &::before{
    left: 0;
    transform-origin: 50% 50%;
  }
  &::after{
    right: 0;
    transform-origin: 50% 50%;
  }
}
