@import "_components/_includs/_mixins.scss";
.services{
  padding-bottom: 30px;
  .container{
    flex-wrap: wrap;
  }
  transition: opacity 0.2s;
  &.off{

    opacity: 0;
  }
}
.services__filter{
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;

  @include media-custom(480px){
    flex-direction: column;
    .all-services &{
      flex-direction: column-reverse;
    }
  };
  .filter__item{
    text-align: center;
    color: #a8a8a8;
    font-family: 'Univers';
    font-size: 35px;
    display: flex;
    align-items: center;
    @include media(tablet){
      font-size: 26px;
    };
    @include media(tel){
      font-size: 20px;
    };
    padding: em(((55-35)/2),35) em(22,35);
    cursor: pointer;
    transition: all 0.3s;
    // &:first-child{
    //   padding-left: 0;
    // }
    &:hover,
    &.active{
      color: #fff;
      background-color: $theme-color;
    }
    &--white{
      background-color: #fff;
    }
  }

  .close-services{
    @extend .filter__item;
    position: relative;
    font-size: 28px;
    @include media(tel){
      font-size: 20px;
      white-space: normal;
    };
    background-color: #312f34;
    color: #a8a8a8;
    padding-right: 64px;
    // line-height: 39px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    // @include media-custom(1240px){
    //   line-height: 1;
    // };
  }
  .close-icon{
    position: absolute;
    right: 29px;
    top: 50%;
    transform: translateY(-50%);
    &::after,
    &::before{
      content: '';
      position: absolute;
      transform-origin: 50% 50%;
      height: 2px;
      background-color: currentColor;
      width: em(53,35);
      top: 50%;
      left: 50%;
      transition: all 0.3s;
    }
    &::after{
      transform: translate(-50%,-50%) rotate(45deg);
    }
    &::before{
        transform: translate(-50%,-50%) rotate(-45deg);
    }

  }
}
.filter{

}
.filtr-container{
  width: 100%;
  position: relative;
  background-image: url(../img/pictures/serv-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  &::after{
    content: '';
    @include coverlayer;
    background-color: rgba($theme-color, 0.85);
    z-index: 1;
  }
  .filtr-item{
    width: 100%;
    display: flex;
    @include media(tablet){
      flex-direction: column;
    };
    position: relative;
    z-index: 10;
    border-bottom: 1px solid #68a9b3;
    padding-top: 52/1200*100%;
    padding-bottom: 28/1200*100%;
    padding-left: 31/1200*100%;
  }
}

.filtr-item__name{
  color: #fff;
  font-family: 'Univers';
  font-size: 26px;
  padding-right: 10px;

  width: 426/1200*100%;
  @include media(tablet){
    width: 100%;
    margin-bottom: 20px;
    padding-right: 0;
  };
}
.filtr-item__desc,
.filtr-item ul:not(.pagination__list){
  width: (1200-426)/1200*100%;
  @include media(tablet){
    width: 100%;
  };
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 19px;
  @include media(tel){
    font-size: 16px;
  };
  line-height: (22/19);
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  li{
    color: #fff;
    padding-left: 17px;
    position: relative;
    width: 356/(1200-426)*100%;
    @include media(tablet){
      width: 50%;
    };
    @include media(tel){
      width: 100%;
      padding-right: 0;
    };
    margin-bottom: 15/(1200-426)*100%;
    padding-right: 10px;
    &::before{
      content: '';
      @include size(em(9));
      border-radius: 100%;
      background-color: currentColor;
      position: absolute;
      left: 0;
      top: em(6);
      transition: all 0.3s;
    }
    &:hover{
      color: #141414;
      font-style: italic;
    }
  }
}
