@import "_includs/_mixins.scss";

.social{
  display: inline-flex;
  margin: 11px 0 0 0;
  padding: 0;
  list-style: none;
  // &--dark{
  //   width: 100%;
  //
  //   .ya-share2__container_size_m .ya-share2__item_service_gplus .ya-share2__icon,
  //   .ya-share2__container_size_m .ya-share2__item_service_twitter .ya-share2__icon,
  //   .ya-share2__container_size_m .ya-share2__item_service_facebook .ya-share2__icon,
  //   .ya-share2__container_size_m .ya-share2__item_service_vkontakte .ya-share2__icon{
  //         background-image: url(../img/images/sprite.png)!important;
  //         width: 40px!important;
  //         height: 39px!important;
  //         display: block!important;
  //         transition: none!important;
  //   }
  //   .ya-share2__container_size_m .ya-share2__item_service_gplus .ya-share2__badge,
  //   .ya-share2__container_size_m .ya-share2__item_service_twitter .ya-share2__badge,
  //   .ya-share2__container_size_m .ya-share2__item_service_facebook .ya-share2__badge,
  //   .ya-share2__container_size_m .ya-share2__item_service_vkontakte .ya-share2__badge{
  //       background-color: transparent !important;
  //   }
  //   .ya-share2__container_size_m .ya-share2__item_service_twitter .ya-share2__icon{
  //     background-position: -260px -165px !important;
  //
  //     &:hover{
  //           background-position: -260px -126px !important;
  //     }
  //   }
  //   .ya-share2__container_size_m .ya-share2__item_service_gplus .ya-share2__icon{
  //     background-position: -80px -232px !important;
  //
  //     &:hover{
  //           background-position: -260px -87px !important;
  //     }
  //   }
  //   .ya-share2__container_size_m .ya-share2__item_service_facebook .ya-share2__icon{
  //     background-position: -260px -48px !important;
  //
  //     &:hover{
  //           background-position: 0 -232px !important;
  //     }
  //   }
  //   .ya-share2__container_size_m .ya-share2__item_service_vkontakte .ya-share2__icon{
  //     background-position: -40px -232px !important;
  //
  //     &:hover{
  //           background-position: -160px -232px !important;
  //     }
  //   }
  // }
  .ya-share2__item{
    transition: all 0.3s;
    @for $i from 1 through 4 {
      &:nth-child(#{$i}){
          transition-delay: $i*100 + ms;
        }
    }
  }
  &.closed{
    .social__item,
    .ya-share2__item{
      transform: translateY(-100%);
      opacity: 0;
      visibility: hidden;
    }
  }
}
.social__item{
  transition: all 0.1s;
  &:not(:last-child){
    margin-right: 8px;
  }
  a{
    display: block;
    transition: none !important;
  }
  &--gp{
    a{
      @include sprite($gp);
    }

    &:hover{
      a{
        @include sprite($gp_active);
      }
    }
  }
  &--tw{
    a{
      @include sprite($tw);
    }
    &:hover{
      a{
        @include sprite($tw_active);
      }
    }
  }
  &--vk{
    a{
      @include sprite($vk);
    }
    &:hover{
      a{
        @include sprite($vk_active);
      }
    }
  }
  &--fb{
    a{
      @include sprite($fb);
    }
    &:hover{
      a{
        @include sprite($fb_active);
      }
    }
  }
  &--ld{
    a{
      @include sprite($ld);
    }
    &:hover{
      a{
        @include sprite($ld_active);
      }
    }
  }
  // &--gp-dark{
  //   a{
  //     @include sprite($gp1);
  //   }
  //   &:hover{
  //     a{
  //       @include sprite($gp1_active);
  //     }
  //   }
  // }
  // &--tw-dark{
  //   a{
  //     @include sprite($tw1);
  //   }
  //   &:hover{
  //     a{
  //       @include sprite($tw1_active);
  //     }
  //   }
  // }
  // &--vk-dark{
  //   a{
  //     @include sprite($vk1);
  //   }
  //   &:hover{
  //     a{
  //       @include sprite($vk1_active);
  //     }
  //   }
  // }
  // &--fb-dark{
  //   a{
  //     @include sprite($fb1);
  //   }
  //   &:hover{
  //     a{
  //       @include sprite($fb1_active);
  //     }
  //   }
  // }

  @for $i from 1 through 4 {
    &:nth-child(#{$i}){
        transition-delay: $i*100 + ms;
      }
  }


}
