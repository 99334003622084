@import "_components/_includs/_mixins.scss";

.general-search{
  width: 100%;
  margin-bottom: 30px;
}
.general-search__wrap{
  display: flex;
  flex-wrap: wrap;
  .form-group{
    padding-top: 8px;
    margin-bottom: 8px;
    &:first-of-type{
      padding-top: 0;
    }

    &--small{
      width: 55%;
    }
  }
  .base-selects .submit-wrap {
      padding-top: 10px;
      @include media(tel){
        padding-top: 10px;
      };
  }
}
.general-search__tip{
  color: #97c8cf;
  font-size: 18px;
  display: block;
  text-align: right;
  margin-top: 14px;
  @include media(tel){
    text-align: center;
  };
}
.general-search__left-col{
  display: flex;
  align-items: flex-start;
  width: 658/(1200-21*2)*100%;
  @include media(tel){
    width: 100%;
    .from-to{
      margin-top: 0;
    }
  };
  .form-group{
    flex: 1;
  }
  @include media-custom(560px){
    flex-wrap: wrap;
    .from-to{
      width: 100%;
      margin-bottom: 15px;
    }
    .form-group{
      width: 100%;
      flex: auto;
      text-align: center;
      margin-bottom: 15px;
    }
  };
  &--small{
    width: 40%;
    @include media(tel){
      width: 100%;
    };
  }
}
.general-search__right-col{
  width: 470/(1200-21*2)*100%;
  @include media(tel){
    width: 100%;

    .show-last{
      em{
        margin-bottom: 15px;
      }
    }
  };
  &--big{
    width: 56%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @include media(tel){
      width: 100%;
    };
    .form-group--small,
    .from-to{
      @include media(tablet){
        width: 100%;
        max-width: none;
      };
    }
    .from-to{
      @include media(tablet){
        margin-top: 15px;
      };
      .select2{
        @include media(tablet){
          max-width: 150px;
        };
      }
    }
    .from-to__item{
      height: 44px;
      em{
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }
}

.general-search__result-text{
  text-align: center;
  color: #fff;
  font-family: 'Univers';
  font-size: 31px;
  padding-left: 21px;

  .base-downloads &{
    margin-right: auto;
  }
  span{
    color: #135b65;
  }
}

.search-result-section{
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  margin-bottom: 16px;

  &--services{
    background-image: url(../img/pictures/serv-serch-bg.jpg);
  }
  &--markets{
    background-image: url(../img/pictures/mark-serch-bg.jpg);
  }
  &--plans{
    background-image: url(../img/pictures/plan-serch-bg.jpg);
  }
  &--base{
    background-image: url(../img/pictures/base-serch-bg.jpg);
  }
  &--press{
    background-image: url(../img/pictures/press-serch-bg.jpg);
  }
}
.search-result-section__over{
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  min-height: 122px;
  background: rgba(#fff, 0.85);
  font-family: 'Univers';
  transition: background 0.3s;
  @include media(tel){
    flex-wrap: wrap;
  };
  &:hover{
    background: rgba(#fff, 0.6);
  }
}
.search-result-section__title{
  width: (667-37)/1200*100%;
  font-size: 40px;
  color: #212121;
  margin: 20px 0 20px 36/1200*100%;

  @include media(tel){
    font-size: 30px;
    width: 100%;
    text-align: center;
    margin-left: 0;
  };

  a{
    position: relative;
    @include underline(0.3s);
    &:hover{
      @include underline-hovered;
    }
  }
}
.search-result-section__count{
  margin: 20px 0 20px 36/1200*100%;
  width: (535-37)/1200*100%;
  text-align: left;
  font-size: 32px;
  @include media(tel){
    font-size: 25px;
    width: 100%;
    text-align: center;
    margin-left: 0;
  };
  a{
    text-align: center;
    // white-space: nowrap;
    display: inline-block;
    padding: (52-32)/2+px 29px;
    border-radius: 5px;

    color: #fff;
    background-color: rgba(#000,0.61);
    &:hover{
      background-color: #000;
    }
  }
}

.general-search-nothing__body{
  width: 100%;
  text-align: center;
  padding-top: 20px;
}
.general-search-nothing__text{
  color: #212121;
  font-family: 'Univers';
  font-size: 27px;
  margin-bottom: 28px;
}
.for-more-info{
  color: #5b5b5b;
  font-size: 19px;
  margin-bottom: 90px;
  line-height: (22/19);
  span{
    color: $theme-color;
  }
  a{
    position: relative;
    @include underline(0.3s);
    &:hover{
      @include underline-hovered;
    }
  }
}
.general-search-nothing__propose{
  background-color: $theme-color;
  width: 100%;
  padding: 43/1200*100% 60/1200*100% 80/1200*100%;
}
