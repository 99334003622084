@import "_components/_includs/_mixins.scss";

.market-details{
  border-bottom: 1px solid #e8e8e8;
  .s-header{
    padding-top: 0;
    width: 100%;
    margin-bottom: 60px;
  }
  .container{
    flex-wrap: wrap;
  }
}

.market-details__content{

}
.market-details__general{
  display: flex;
  justify-content: space-between;
  @include media(tel){
    flex-wrap: wrap;
  };
}
.market-details__image{
  position: relative;
  overflow: hidden;
  width: 330px;
  // flex-grow: 1;
  @include media(tel){
    // min-height: 320px;
    width: 100%;
    margin-bottom: 15px;
  };

  img{
    width: 100%;
    // max-width: none;
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%,-50%);
  }
}
.market-details__feature{
  margin-left: 32px;
  width: 70%;
  display: flex;
  flex-direction: column;
  @include media(tel){
    margin-left: 0;
    width: 100%;
  };
  .price{
    font-size: 47px;
    text-transform: uppercase;
    font-family: 'Univers';
    color: $theme-color;
    margin-bottom: 33px;
    span{
      color: #3b3b3b;
      text-transform: none;
      font-size: 20px;
      font-family: 'Calibri';
    }
  }
  .link{
    margin-bottom: 15px;
    .btn{
      font-size: 32px;
      &:hover{
        border-color: $theme-color;
      }
    }
  }
  .info{
    margin-top: auto;
  }
  .info__title{
    padding-bottom: 15px;
    margin-bottom: 5px;
    text-transform: uppercase;
    font-weight: 700;
    color: #212121;
    font-size: 18px;
    border-bottom: 1px solid $theme-color;
  }
}


.info-table{
  width: 100%;
  text-align: left;
  font-size: 17px;
  color: #626262;
  table-layout: fixed;
  td{
    padding: (35-14-18/2)+px 0;
    width: 50%;
    &:first-child{
      .market-details__feature &{
        width: 35%;
      }
      color: #4a4a4a;
      font-weight: 700;
      font-size: 18px;
    }
  }
}

.market-details__text{
  margin-top: 71px;
  width: 100%;
}
.text-info{
  margin-bottom: 57px;
  &:last-of-type{
    margin-bottom: 41px;
  }
  p{
    margin: 0;
  }
}
.text-info__title{
  text-transform: uppercase;
  font-family: 'Univers';
  color: #212121;
  font-size: 40px;
  margin-bottom: 22px;
}
