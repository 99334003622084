@import "_components/_includs/_mixins.scss";

.plan__price{

}
.price{
  color: #3b3b3b;
  font-size: 20px;
  padding-bottom: 7px;
  span{
    font-size: 47px;
    position: relative;
    font-family: 'Univers';
    bottom: -5px;
    color: $theme-color;
  }
}
