@import "_components/_includs/_mixins.scss";
.customers{
  padding-bottom: 68px;
  .container{
    flex-wrap: wrap;
  }
  // .pagination{
  //   margin-top: 41px;
  // }
}
.customers__filter{
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}
.customers__filter-item{
  font-size: 35px;
  color: #a8a8a8;
  font-family: 'Univers';
  a{
    display: block;
    padding: em((55-35)/2,35) em(18,35);
  }
  &:hover,
  &.active{
    color: $theme-color;
    // background-color: #ededed;
  }
}
.customers__tabs{
  width: 100%;
}
.tabs__item{
  width: 100%;
}
.customers-slider__inner{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .customers-group &{
    justify-content: flex-start;
  }
}
.company{
  // background-color: #ededed;
  position: relative;
  width: 230/1020*100%;

  .company__logo::before,
  .company__logo::after,
  &::before,
  &::after{
    content: '';
    position: absolute;
    background: $theme-color;
    z-index: 20;
    transition: all 0.3s;
  }
  &::before,
  &::after{
    top: 0;
    left: 0;
    transform: translate(20px, 20px);
    opacity: 0;
  }
  .company__logo::before,
  &::before{
    width: 50px;
    height: 5px;
  }
  .company__logo::after,
  &::after{
    width: 5px;
    height: 50px;
  }
  .company__logo::before,
  .company__logo::after{
    right: 0;
    bottom: 0;
    transform: translate(-20px, -20px);
    opacity: 0;
  }

  .customers-group &{
    margin-right: 3.25%;

    &:nth-child(4){
      margin-right: 0;
    }
  }


  @include media(tablet){
    width: (230*2+30)/1020*100%;
  };
  @include media(tel){
    width: 100%;
  };
  height: 230/1020*100%;
  min-height: 214px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 0 5px;
  margin-bottom: 30px;

  &:hover{
    // background-color: darken(#ededed, 10%);
    .company__logo::before,
    .company__logo::after,
    &::before,
    &::after{
      transform: translate(0px,0px);
      opacity: 1;
    }
  }
}
.company__logo{
  margin-bottom: 8px;
}
.company__name{
  font-size: 15px;
  color: #3b3b3b;
}
.slider-response{
  width: (230*2+30)/1020*100%;
  @include media(tablet){
    width: 100%;
  };
  margin-bottom: 30px;
  position: relative;
}
.slider-response__title{
  color: #212121;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  font-size: 22px;
}
.slider-response__logo{
  position: absolute;
  top: 12px;
  right: 0;
  width: 100px;
  height: 55px;
  text-align: center;
  img{
    display: inline-block;
    max-width: 100px;
    max-height: 55px;
  }
}
.slider-response__heading{
  color: #323232;
  font-family: 'Univers';
  text-transform: uppercase;
  font-size: 19px;
  margin-top: 28px;
  margin-bottom: 17px;
  padding-right: 100px;
}
.slider-response__text{
  font-size: 16px;
  line-height: (20/16);
  margin: 0;
}
.slider-response__link{
  font-size: 18px;
  color: $theme-color;
  text-align: right;
  margin-top: 11px;
  a{
    position: relative;
    @include underline(0.3s);

    &:hover{
      @include underline-hovered;
    }
  }
}

.branches{
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-between;
}
.branches__item{
  margin-bottom: 20px;
  margin-right: 20px;
  &:nth-child(4n){
    margin-right: 0;
  }
  width: calc(25% - 15px);
  @include media(tablet){
    width: calc((100/3*1%) - 15px);
    &:nth-child(3n){
      margin-right: 0;
    }
    &:nth-child(4n){
      margin-right: 20px;
    }
  };
  @include media(tablet){
    width: calc(50% - 10px);
    &:nth-child(3n){
      margin-right: 20px;
    }
    &:nth-child(4n){
      margin-right: 0;
    }
    &:nth-child(2n){
      margin-right: 0;
    }
  };
  @include media-custom(550px){
    width: 100%;
    margin-right: 0;
    &:nth-child(3n){
      margin-right: 0;
    }
  };

  .holder__image{
    height: 306px;
    @include media(tel){
      max-height: none;
    };
  }
  .holder__title{
    font-size: 38px;
    margin: 0 0 27px;
  }
}
