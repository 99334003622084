@import "_components/_includs/_mixins.scss";
.press-center{
  .container{
    flex-wrap: wrap;
  }
}
.press-center-filter{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  margin: 0 0 47px 0;
  padding: 0;
  font-size: 35px;
  font-family: 'Univers';
  font-weight: 400;
  @include media(tablet){
    font-size: 25px;
  };
  @include media(tel){
    font-size: 20px;
    flex-wrap: wrap;
    justify-content: space-around;
  };

}
.press-center-filter__item{
  color: #a8a8a8;
  line-height: (35/36);
  text-align: center;
  width: 20%;

  @include media(tel){
    width: 50%;
  };
  &:hover,
  &.active{
    a{
      color: $theme-color;
      // background-color: #ededed;
    }
  }
  a{
    display: block;
    padding: (55-34)/2+px 0;
  }
}
.press-center__wrap{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  @include media(tel){
    justify-content: space-between;
  };
}
.press-center__item{
  width: (380/1200)*100%;
  display: inline-block;

  .press-center__wrap &{
    &:not(:nth-child(3n)){
      margin-right: 2.5%;

      @include media(tel){
        margin-right: 0;
      };
    }
  }
  .other-news-slider &{
    width: 100%;
    max-width: 302px;
    margin-right: 5px;
    @include media(tel){
      margin-right: 0;
    };
  }
  @include media(tel){
    width: calc(50% - 15px);
  };
  @include media-custom(550px){
    width: 100%;
  };
  border: 1px solid #d4d5d5;
  margin-bottom: 31px;
  // text-align: center;
  &:hover{
    border-color: $theme-color;
    .press-center__title{
      @include underline-hovered;
    }
  }

  .news__time{
  
  }
}
.press-center__image{

}
.press-center__text{
  padding: 0 5% 20px;
  p{
    font-size: 16px !important;
    color: #3b3b3b !important;
    font-style: normal !important;
    font-weight: normal !important;
    margin: 0;
  }
  i,em{
    font-style: normal !important;
    font-size: 16px !important;
  }
  strong, b{
    font-weight: normal !important;
    font-size: 16px !important;
  }
}
.press-center__title{
  display: inline-block;
  position: relative;
  @include underline(0.5s);
  color: #212121;
  font-size: 18px;
  text-transform: uppercase;
  margin-top: 15px;

  font-weight: 700;
  font-family: 'Calibri';
}

.press-center__settings{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 44px;
  margin-bottom: 50px;
  @include media-custom(650px){
    flex-direction: column-reverse;
  };
}
//
// .display-count-label{
//   display: flex;
//   align-items: center;
//   position: relative;
//   @include media-custom(650px){
//     margin-bottom: 15px;
//   };
//
//   span.txt{
//     text-transform: uppercase;
//     margin-right: 15px;
//     color: #323232;
//     font-size: 17px;
//     font-family: 'Univers';
//     white-space: nowrap;
//     font-weight: 700;
//   }
//   .select2{
//     max-width: 58px;
//
//   }
//   .select2-selection{
//     border: 1px solid #bfbfbf;
//     border-radius: 3px;
//     font-family: 'Univers';
//     color: #323232;
//     font-weight: 700;
//   }
// }
.press-search-title{
  font-family: 'Univers';
  color: #212121;
  font-size: 38px;
  width: 100%;
  text-align: center;
  margin-bottom: 31px;
}
