@import "_components/_includs/_mixins.scss";

.service{
  padding-bottom: 25px;
  .container{
    flex-wrap: wrap;
  }
  .s-header{
    padding-top: 0;
  }
  // .social{
    // padding-top: 18px;
  // }
}
.service__content{
  margin-top: 48px;
  width: 100%;
  @include cf;
  p{
    margin-top: 0;
    line-height: 22px;
    margin-bottom: 23px;
  }
}
.service__thumb{
  max-width: 633px;
  padding-right: 25px;
  margin-bottom: 15px;
  float: left;
  @include media(tablet){
    padding-right: 0;
    float: none;
    text-align: center;
    max-width: none;
  };
}

.service__links{
  display: flex;
  width: 100%;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 53px;

  .btn{
    font-size: 32px;
    width: 575/1200*100%;
    @include media(tel){
      width: 100%;
      font-size: 25px;
    };
    padding: em(18,32) 1%;
    margin-bottom: 30px;
  }
}

.all-services{
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  top: 0;
  left: 0;
  @include size(100%);
  z-index: 150;
  transition: all 0.3s;

  &.hidden{
    opacity: 0;
    visibility: hidden;
    .all-services__inner{
      transform: translateX(-100%);
    }
  }
}
.all-services__inner{
  position: absolute;
  width: 100%;
  top: (140/1920*100)+vw;
  transition: transform 0.3s;
  transition-timing-function: cubic-bezier(.85,.09,1,.58);
}
.all-services__body{
  padding-bottom: (140/1920*100)+vw;
  flex-wrap: wrap;
  .filter__item{
    &:last-child{
      flex: 1;
    }
    // flex:1;
    // white-space: nowrap;
  }
}

.all-services-overlay{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(#000, 0.43);
  z-index: 100;
  opacity: 1;
  visibility: visible;
  transition: all 0.3s;
  cursor: pointer;

  &.hidden{
    opacity: 0;
    visibility: hidden;
  }
}
