@import "_components/_includs/_mixins.scss";
.press-video{
  .press__soc{
    margin-top: 30px;
  }
}
.press-video__wrap{
  margin: 0 auto;
  max-width: 953px;
}
.press-video__name{
  text-align: center;
  font-size: 38px;
  color: #212121;
  margin-top: 11px;
  margin-bottom: 38/953*100%;
}
.video-holder{
  text-align: center;
  margin-bottom: 21px;
  iframe{
    width: 100%;
  }
}
.press-video__text{
  p{
    margin-top: 0;
  }
}
.press-video__info{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
  font-size: 18px;
}
.press-video__time{
  color: #212121;
}
.press-video__next-link{
  text-align: right;
  color: $theme-color;
  position: relative;
  @include underline(0.3s);
  &:hover{
    @include underline-hovered;
  }
}
