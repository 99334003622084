@import "_includs/_mixins.scss";

.page-header{
  position: relative;
  min-height: 287px;
  display: flex;
  padding-bottom: 39px;
  padding-top: 115px;
  @include media-custom(1024px){
    padding-top: 220px;
  };
  @include media(tablet){
    background-attachment: fixed;
  };

  &::after{
    content: '';
    background-color: rgba(#000, 0.38);
    @include coverlayer;
  }

  .container{
    position: relative;
    z-index: 10;
  }
}

.page-header__info{
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  padding-right: 280px;
  @include media-custom(1024px){
    flex-direction: column;
    padding-right: 0;
  };
}

.base-link{
  position: absolute;
  right: 0;
  bottom: -132px;
  white-space: nowrap;
  z-index: 10;


  @include media-custom(1024px){
    right: 50%;
    transform: translateX(50%);
    bottom: -85px;
  };
}
.page-title{
  margin: 0;
  @include media(tablet){
    // margin-bottom: 15px;
    text-align: center;
  };
  color: #fff;
  font-size: 59px;
  @include media-custom(1024px){
    font-size: 50px;
  };
  @include media(tel){
    font-size: 40px;
  };
  text-transform: uppercase;
  font-family: 'Univers';
}
