@import "_components/_includs/_mixins.scss";
.vacancy-single {
  padding-bottom: 15px;
  .container {
    flex-wrap: wrap;
  }
}


.vacancy-single__left {
  width: 380px;
  @include media(tel){
    width: 100%;
  };
  text-align: center;
  margin-bottom: 26px;
  .social{
    margin-top: 26px;
  }
}

.vacancy-single__image {
  margin: 0;
  padding: 37px 0;
  background-color: #efefef;
  width: 100%;
}

.vacancy-single__name {
  @extend .vacancy__name;
}

.vacancy-single__right {
  width: calc(100% - 410px);
  @include media(tel){
    width: 100%;
  };
  margin-bottom: 26px;
}

.vacancy-single__title {
  color: #212121;
  font-size: 38px;
  margin: 0 0 em(29,38);
  line-height: (39/38);
}

.vacancy-single__require {
  font-size: 19px;
  strong{
    color: #212121;
    text-transform: uppercase;
    font-size: 18px;
    margin-bottom: 9px;
    display: block;
  }
  ul{
    margin: 0;
    padding: 0;
    margin: 0;
    list-style: none;
    line-height: (22/19);
  }
  li{
    position: relative;
    text-indent: 12px;
    margin-bottom: 23px;
    &::before{
      content: '-';
      position: absolute;
      top: 0;
      left: -12px;
    }
  }
}

.vacancy-single__contacts {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 11px;
  .info {
    max-width: 100%;
  }

  .info__item {
    display: flex;
    margin-bottom: 10px;
    @include media-custom(450px){
      flex-direction: column;
    };
  }

  .info__name {
    color: #4a4a4a;
    font-size: 18px;
    width: 170px;
    @include media-custom(450px){
    margin-bottom: 5px;
    };
  }

  .info__data {
    font-size: 17px;
    text-overflow: ellipsis;
    overflow: hidden;
    a{
      white-space: nowrap;
      position: relative;
      @include underline(0.3s);
      &:hover{
        @include underline-hovered;
      }
    }
  }

  .cv {
    .btn{
      padding-left: 13px;
      padding-right: 13px;
      @include media-custom(400px){
        margin-bottom: 5px;
      };
      &:last-child{
        margin-left: 5px;
        @include media-custom(400px){
          margin-left: 0;
        };
      }
    }
  }
}


.btn {

}
