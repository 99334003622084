@import "_components/_includs/_mixins.scss";
.shop{
  .container{
    flex-wrap: wrap;
  }
}

.shop__item{
  width: 100%;
  display: flex;
  background-color: #f0f0f0;
  &:nth-child(even){
    flex-direction: row-reverse;
    @include media(tel){
      flex-direction: column;
    };
  }
  @include media(tel){
    flex-direction: column;
  };
}
.product{
  width: 100%;
  display: flex;

  &:hover{
    .product__link{
      img{
        transform: translate(-50%,-50%) scale(1.2);
      }
    }
    .product__title{
      color: $theme-color;
    }
  }
}
.product__item{
  width: 50%;
  height: 416px;
  @include media(tel){
    width: 100%;
    height: 50vh;
  };
  @include media(tablet){
    height: 350px;
  };
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  text-align: center;
}
.product__link{
  color: #fff;
  font-size: 24px;
  font-family: 'Univers';
  em{
    font-style: normal;
    font-size: 26px;
  }
  img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    min-width: 100%;
    max-width: none;
    height: 100%;
    z-index: 0;
    transition: all 8s;
  }

  &::after{
    content: '';
    background-color: rgba(#000, 0.69);
    @include coverlayer;
  }
}
.product__name{
  position: relative;
  z-index: 10;
}
.product__desc{

  p{
    width: 500/600*100%;
    font-size: 16px;
    color: #3b3b3b;
    margin-top: 0;
    line-height: (19.5/16);
  }
}
.product__title{
  text-transform: uppercase;
  font-size: 18px;
  color: #212121;
  font-family: 'Calibri';
  font-weight: 700;
  margin-bottom: 26px;

  a{
    position: relative;
    @include underline(0.3s);
    &:hover{
        @include underline-hovered;
    }
  }
}
