@import "_includs/_mixins.scss";

.hidden-nav{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  max-height: 100%;
  @include media-custom(1024px){
    overflow: auto;
  };
  background-color: rgba(lighten(desaturate(adjust-hue(#00879c, 5.8074), 46.2222), 13.5294), 0.9);
  padding: 21px 0 5px 0;
  @include media(tel){
    padding-top: 15vh;
  };
  z-index: 1000;
  transition: background-color 0.3s, opacity 0.3s, visibility 0.3s, transform 0.3s;

  a{
    display: block;
    position: relative;
  }

  &.closed{
    background-color: transparent;
    visibility: hidden;
    overflow: hidden;

    .hidden-nav__item,
    .btn--hidden-nav{
      opacity: 0;
      visibility: hidden;
      transform: translateY(-100px);
    }
  }
}

.hidden-nav__container{
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;

}

.hidden-nav__list{
  margin: 0;
  padding: 0;
  list-style: none;
  color: #fff;

  @include cf;
}

.hidden-nav__item{
  width:  percentage(463/1200);
  margin-right: percentage(65/1200);
  float: left;
  margin-bottom: 11px;
  @include media(tel){
    float: none;
    margin-right: 0;
    width: 100%;
    position: relative;
  };

  transition: all 0.3s;

  &:nth-child(2){
    .hidden-sub-nav__item:last-child{
      position: absolute;
      bottom: 100%;
      text-transform: uppercase;
      font-size: 39px;
      border: none;
      font-family: 'Univers';
      z-index: 10000;

      a:after{
        content: none;
      }
    }
  }

  &:first-child{
    @include cf;
    width: percentage((463*2+65)/1200);
    @include media(tel){
      width: 100%;
    };
    .hidden-sub-nav{
      float: left;
      width: percentage((463)/(463*2+65));
      @include media(tel){
        float: none;
        width: 100%;
      };

      &:first-of-type{
        margin-right: percentage(65/(463*2+65));
        @include media(tel){
          margin-right: 0;
        };
      }
    }
  }
  &:nth-child(2){
    position: relative;
    float: right;
    margin-right: percentage(209/1200);
    @include media(tel){
      float: none;
      margin-right: 0;
    };
  }


  > a{
    text-transform: uppercase;
    font-size: 39px;
    font-family: 'Univers';
    // line-height: 50px;
    &:hover{
      color: #135a70;
    }
  }

  &--active{
    > a{
      color: #135a70;
    }
  }

  &:nth-of-type(1){
    transition: all 0.4s;
  }
  &:nth-of-type(2){
    transition: all 0.4s;
  }
  &:nth-of-type(3){
    transition: all 0.5s;
  }
  &:nth-of-type(4){
    transition: all 0.5s;
  }
  &:nth-of-type(5){
    transition: all 0.5s;
  }
  &:nth-of-type(6){
    transition: all 0.5s;
  }
  &:nth-of-type(7){
    transition: all 0.5s;
  }
}

.hidden-sub-nav{
  margin: 0;
  padding: 0;
  list-style: none;
  font-family: 'Lato';
  font-weight: 300;

  @include media(tel){
    display: none;
  };
  > a{
    color: #212121;
    font-size: 32px;
    font-family: 'Univers';
  }
}

.hidden-sub-nav__item{
  font-size: 23px;
  line-height: (27/23);

  &:hover,
  &--active{
    color: #2b2b2b;

    a::after{
      background-image: none;
    }
  }

  a{
    padding: ((48-23)/2 + px) 0;
    &::after{
      content: '';
      height: 1px;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAABCAYAAADn9T9+AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMDY3IDc5LjE1Nzc0NywgMjAxNS8wMy8zMC0yMzo0MDo0MiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjYxNTM1RjQ1OEE0NjExRTZBMEI0RkE3NEZGRDczQTA1IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjYxNTM1RjQ2OEE0NjExRTZBMEI0RkE3NEZGRDczQTA1Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6NjE1MzVGNDM4QTQ2MTFFNkEwQjRGQTc0RkZENzNBMDUiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NjE1MzVGNDQ4QTQ2MTFFNkEwQjRGQTc0RkZENzNBMDUiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7qD2CVAAAAHklEQVR42mL8//+/BAMDQxIDKpgHxJZArAkTAAgwAJPLBHkq5hIKAAAAAElFTkSuQmCC');
    }
  }
}

@include media(tel){
  .mobile-plus{
    position: absolute;
    top: 6px;
    right: 1.02093vw;
    @include size(25px);
    color: #fff;
    &::before,
    &::after{
      content: '';
      position: absolute;
      background-color: currentColor;
    }
    &::before{
      width: 25px;
      height: 1px;
      left: 0;
      top: 12px;
    }
    &::after{
      height: 25px;
      width: 1px;
      left: 12px;
      top: 0;
      transition: all 0.3s;
      transform-origin: 50% 50%;
    }

    &.opened{
      &::after{
        transform: scaleY(0);
      }
    }
  }
};
