@import "_includs/_mixins.scss";

.base_access{
  margin-bottom: 60px;
}

.error-block{
  width: 100%;
  margin: 30px 0 50px;
  text-align: center;
}
.error-block__ttl{
  color: #000;
  font-size: 30px;
  text-transform: uppercase;
}
.error-block__num{
  font-size: 13vw;
  color: $theme-color;
  font-weight: 700;
}


.enter-page{
  h2{
    font-size: 35px;
    margin-bottom: 17px;
  }
}
.enter-page__wrp{
  width: 800px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 60px;
}
.enter-page__form{
  width: 100%;
 background: $theme-color;
 padding: 20px 20px 30px 20px;

 .form-group{
   margin-bottom: 20px;
 }

 .btn{
   font-size: 32px;
    padding: .3125em 1.34375em;;
 }
 .enter-links{
   text-align: left;
   margin-bottom: 20px;
  a{
    color:#fff;
    position: relative;
    display: inline-block;
    @include underline(0.3s);

    &::after{
      background: #fff !important;

    }

    &:hover{
      @include underline-hovered;
    }

    &:first-child{
      margin-right: 10px;
    }
  }
 }
}

.payment{
  text-align: center;
  .btn{
    // display: inline-block;
    margin-top: 15px;
  }
  form{
      text-align: center;
  }
  .form-payment{
    // text-align: left;
    color: #fff;
    font-size: 25px;
    font-family: 'Univers';
    margin-bottom: 5px;

    span{
      display: inline-block;
      width: 70px;
    }
  }
}

.services ~ .btn--ask{
  background: rgba(0,0,0,.61);
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered li{
  margin-right: 0;
  width: 100%;
  min-height: 35px;
  display: flex;
  align-items: center;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice{
  border-color: $theme-color;
  color: $theme-color;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove{
  color: $theme-color;
  font-size: 20px;
}
.select2-container--default .select2-search--inline .select2-search__field{
  width: 100%!important;

}
.select2-results__option.select2-results__message{
  background:rgba(0,0,0,.61);
  color: #fff;
  position: absolute;
}
.select2-container--default .select2-selection--multiple .select2-selection__rendered,
.select2-container--default .select2-selection--multiple{
  min-height: 35px;
}

.search-out-item__name a:hover{
  color: $theme-color;
}

.select2-container{
  z-index: 10;
}

.base-txt{
  display: none;
}
.page-header-base{
  @include media-custom(1024px){
    padding-top: 250px;
    text-align: center;
  };
  .page-title{
    position: relative;
    width: 100%;
    max-width: 100%;
    font-size: 53px;
  }
  .base-count{
    font-size: 0.75em;
  }
  .base-txt{
    display: block;
    position: absolute;
    left: 0;
    bottom: 100%;
    width: 100%;
    max-width: 100%;
    font-size: 0.75em;
  }
}
.select2-container--open{
  z-index: 100;
  .popup-opened &{
    z-index: 1020;
  }
}
.select2-dropdown{
  background: #ececec;
}

.btn-logged{
  .a-text{
    font-size: 1.4rem;
  }
  .a-count{
    font-size: 1rem;
    text-transform: none;
  }
  a{
    &:hover{
      color: $theme-color;
    }
  }
}


#register{
  .register__right-col{
    .col-inner:nth-child(2){
      display: flex;
      flex-direction: column;

      .submit-wrap{
        margin-top: auto;
      }
    }
  }

  .or{
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    font-family: 'Univers';
    font-size: 40px;
    @include media(tableet){
      display: none;
    };
  }
}
