@import "_includs/_mixins.scss";

.footer{
  background-color: #f1f1f1;
  padding-top: rem(43);
  // border-top: $border;


  strong{
    margin-top: 0;
    display: block;
    font-family: 'Univers';
    font-size: rem(22);
    font-weight: normal;
    text-transform: uppercase;
    color: #212121;
    line-height: 1;
    margin-bottom: rem(14);
  }

  a{
    @include state{
      color: $theme_color;

    }
  }

}

.footer__links{
  width: percentage(712/1200);
  // margin-right: calcWidth(70);
  margin-bottom: rem(58);

  @include media(tablet){
    margin-right: 0;
    width: 100%;
  }
}

.categories{
  display: flex;
  justify-content: space-between;

  @include media-custom(600px){
  flex-flow: column;
  // align-items: center;
  }
}

.categories__item{
  margin-bottom: rem(8);
  margin-right: auto;
  ul{
    @extend %clean-ul;
  }
  li{
    line-height: 21 / $root-font-size;
    margin-bottom: rem(12);
  }

  a{
    position: relative;
    @include underline(0.3s);

    &:hover{
      @include underline-hovered;
    }
    @include media-custom(600px){
      display: block;
    }
  }
  @include media-custom(600px){
    margin-left: auto;
    text-align: center;
  }
}

.brands{
  position: relative;
  display: flex;
  overflow: hidden;
  justify-content: space-between;
  @include media(tel){
    align-items: center;
    flex-direction: column;
  }
}

.brands__main{
  color: #fff;
  background-color: currentColor;
  position: relative;
  z-index: 10;
  &::after{
    content: '';
    position: absolute;
    top: 50%;
    right: -15px;
    transform: translateY(-50%);
    @include size(0);
    border-left: 15px solid currentColor;
    border-right: none;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    @include media(tel){
      left: 50%;
      transform: rotate(90deg) translateX(-50%);
      bottom: -35px;
      right: auto;
      top: auto;
    };
  }
  @include media(tel){
    position: relative;
    bottom: -1px;
  };
}

.brands__list{

  z-index: 5;
  flex-grow: 1;
  margin: 0;
  list-style: none;
  padding: 0 13px 0 22px;
  display: flex;
  border: 1px solid #d7d7d7;
  border-left: none;
  align-items: center;
  justify-content: space-between;


  @include media(tel){
    border-left: 1px solid #d7d7d7;
    padding: 25px 10px 10px;
    flex-wrap: wrap;
    max-width: 460px;
  }

  .first-visit &{
    // position: absolute;
    // bottom: 0;
    // top: 0;
    // right: 10px;
    // transform: translateX(-100%);
    // transition: all 0.6s;
    // transition-timing-function: linear;;
    transition: all 0.4s;
    border-color: transparent;
  }

  .first-visit.in &{
    // transform: translateX(0%);
    border-color: #d7d7d7;
  }
}

.brands__item{
  font-family: 'Univers';


  .first-visit &{
    transition: all 0.2s;
    transform: scale(0);
  }

  .first-visit.in &{
    transform: scale(1);

    @for $i from 1 through 4{
      &:nth-child(#{$i}){
        // color: red;
        transition-delay: $i*100+ms;
      }
    }
    // &:nth-child(1){
    //   transition-delay: 0.1s;
    // }
    // &:nth-child(2){
    //   transition-delay: 0.2s;
    // }
    // &:nth-child(3){
    //   transition-delay: 0.3s;
    // }
    // &:nth-child(4){
    //   transition-delay: 0.4s;
    // }
  }
  // .first-visit.in &{
  //   transform: scale(1);
  //   &:nth-child(1){
  //     transition-delay: 0.5s;
  //   }
  //   &:nth-child(2){
  //     transition-delay: 0.4s;
  //   }
  //   &:nth-child(3){
  //     transition-delay: 0.3s;
  //   }
  //   &:nth-child(4){
  //     transition-delay: 0.2s;
  //   }
  // }

  img{
    transition: box-shadow 0.3s;
  }
  &:hover{
    img{
      box-shadow: 0 0 0 2px $theme-color;
    }
  }

  &:not(:last-child){
    margin-right: 5px;
    @include media(tel){
      margin-right: 0;
    }
  }
  @include media(tel){
    width: 50%;
    margin-bottom: 10px;
    a{
      margin-left: 25px;
    }
  }
}

.standarts{
  text-align: center;
  width: percentage(489/1200);
  display: flex;
  justify-content: space-between;
  // padding-top: rem(5);
  margin-bottom: rem(58);
  @include media(tablet){
    width: 100%;
  }
}

.standarts__title{
  font-family: 'Univers';
  font-size: 20px;
  margin-bottom: 23px;
}

.standarts__item{
  width: percentage(218/489);
  @include media(tablet){
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &:first-of-type{
    margin-left: auto;
  }
  &:last-of-type{
    .standarts__title{
      margin-bottom: 30px;
    }
  }
}


////////////////////////////////////////////FOOTLINE
$content-height: rem(20);

.footline{
  $padding: (80 - 54) / 2;
  color: rgba(#393939, 0.51);
  background-color: #d7d7d7;
  padding: rem($padding) 0;
  line-height: $content-height;

  .container{
    @include media(tel){
      flex-direction: column;
      align-items: center;
    }
  }
}

.master{
  margin-right: 120px;
  @include media-custom(1024px){
    margin-right: 20%;
  };
  @include media(tablet){
    margin-right: 30%;
  };
  @include media(tel){
    margin-right: 0;
    margin-bottom: 50px;
  };
  a{
    color: rgba($theme_color, 0.51);
    font-size: $content-height;
    font-weight: 700;

    @include state{
      color: $theme_color;
    }
  }
}
