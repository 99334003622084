@import "_components/_includs/_mixins.scss";

.mp-about{
  // border-top: $border;
  padding-bottom: rem(48);

  .s-header{
    margin-bottom: rem(19);
  }
  .container{
    flex-flow: column;
    align-items: center;
    text-align: center;
  }
}
.mp-about__text{
  flex: auto;
  width: 100%;
  max-width: rem(800);
  font-size: rem(19);
  line-height: (22/19);
  color: #5b5b5b;
  margin-bottom: rem(18);
  p{
    @include hyphens;
  }
}


.press{
  border-top: $border;
  padding-bottom: rem(30);

  .container{
    flex-wrap: wrap;
  }
  .mp-link{
    width: 100%;
    padding: 4px 0 0;
  }
  .s-header{
    margin-bottom: rem(66);
  }
  .container{
    @include media(tel){
      flex-flow: wrap;
    }
  }
  .news{
    flex: 0 1 calcWidth(280);

    @include media(tel){
      flex:0 1 calc(50% - 17px);
    }
    @include media-custom(480px){
      flex:0 1 100%;
    }

  }
}

.mp-projects{
  border-top: $border;
  .s-header{
    margin-bottom: rem(52);
  }
}




.mp-services{
  border-top: $border;
  .s-header{
    margin-bottom: rem(36);
  }
}

.holder{
  position: relative;
  text-align: center;

  &:hover{
    .holder__image img{
      transform: scale(1.1) translate(-50%,-50%);
    }

    .holder__title a{
      @include underline-hovered;
    }

    .holder__info{
      opacity: 1;
    }
  }
  @include media(tablet){
    .holder__image img{
      transform: scale(1.1) translate(-50%,-50%);
    }

    .holder__title a{
      @include underline-hovered;
    }

    .holder__info{
      opacity: 1;
    }
  };

}

.holder__image{
  margin: 0;
  height: rem(321);
  overflow: hidden;
  position: relative;


  img{
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: none;
    height: 100%;
    transform: scale(1) translate(-50%,-50%);
    transition: all .3s ease-in-out;
    object-fit: cover;
  }
  @include media(tel){
    max-height: rem(210);
  }

}

.holder__info{
  @include coverlayer;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: 10%;

  background: rgba(#0f0f0f, 0.82);

  opacity: 0;
  transition: all .3s;
}

.holder__title{
  font-size: rem(24);
  color: #fff;

  a{
    display: inline-block;
    position: relative;
    @include underline(0.5s);

    &:hover{
      color: $theme_color;
      text-shadow: 1px 1px 1px #000;
    }
  }

  span{
    display: block;
    margin-top: rem(6);
    margin-bottom: 4px;
    font-family: 'Calibri';
    font-weight: 400;
    font-size: rem(17);
  }
}

.mp-link{

  .mp-services &,
  .mp-projects &{
    padding: rem(41) 0 rem(52);
  }

  .clients &{
    padding: rem(36) 0 rem(36);
  }

  .btn{
    margin-left: auto;
  }
}


.clients{
  border-top: $border;
  // padding-bottom: rem(163);

  .s-header{
    margin-bottom: rem(77);
  }
}

.client{
  text-align: center;
  // margin-bottom: 20px;
  display: inline-flex !important;
  flex-direction: column;
  height: 170px;
  align-items: center;
  justify-content: center;
  // background: #ededed;
  margin: 0 10px 5px;
  position: relative;


    figure::before,
    figure::after,
    &::before,
    &::after{
      content: '';
      position: absolute;
      background: $theme-color;
      z-index: 20;
      transition: all 0.3s;
    }
    &::before,
    &::after{
      top: 0;
      left: 0;
      transform: translate(20px, 20px);
      opacity: 0;
    }
    figure::before,
    &::before{
      width: 50px;
      height: 5px;
    }
    figure::after,
    &::after{
      width: 5px;
      height: 50px;
    }
    figure::before,
    figure::after{
      right: 0;
      bottom: 0;
      transform: translate(-20px, -20px);
      opacity: 0;
    }

  &:hover{
    // background: #d4d4d4;
    figure::before,
    figure::after,
    &::before,
    &::after{
      transform: translate(0px,0px);
      opacity: 1;
    }
  }
  figure{
    margin: 0 0 10px;
    padding: 0 5px;
  }
  figcaption{
    font-size: 15px;
  }
  img{
    display: inline-block;
  }
}




@mixin line-animation {
  animation-name: line;
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(1.000, 0.015, 0.190, 0.910);
  animation-fill-mode: forwards;
}

.counts {
    position: relative;
    // background-image: url("../img/pictures/c.jpg");
    font-size: 27px;
    @include media(tablet){
      font-size: 20px;
    };
    padding-top: em(73,27);
    padding-bottom: em(50,27);
    text-align: center;
    color: #fff;
    overflow: hidden;
    z-index: 10;

    @include media(tablet){
      background-attachment: fixed;
    };

    &::before{
      content: '';
      background: rgba(0, 0, 0, 0.69);
      @include coverlayer(-1);
    }
    .container{
      @include media(tel){
        flex-flow: wrap;
      }
    }
}

.counts__item {
    width: 25%;
    font-weight: 100;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    margin-bottom: rem(25);


    &:nth-of-type(1){
      svg{
        @include size(em(66,27),em(74,27));
      }
    }
    &:nth-of-type(2){
      svg{
        @include size(em(65,27),em(94,27));
      }
    }
    &:nth-of-type(3){
      svg{
        @include size(em(76,27),em(75,27));
      }
    }
    &:nth-of-type(4){
      svg{
        @include size(em(70,27),em(70,27));
      }
    }

    @include media(tel){
      width: 50%;
    }
    @include media-custom(520px){
      width: 100%;
    }
}
.counts__image {
  display: flex;
  flex-flow: column;
  align-items: center;;
  justify-content: center;
  height: em(78,27);
  margin-bottom: em(10,27);

  svg{
    display: inline-block;
    vertical-align: top;
  }

}
.counts__number {
  font-family: 'Lato';
  // flex: 0 0;
  font-size: em(74,27);
  position: relative;
  padding-bottom: em(10,74);
  margin-bottom: em(13,74);
  &::after{
    content: '';
    position: absolute;
    @include size(em(4,74), 0);
    background-color: $theme_color;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  &.animated{
    &::after{
      @include line-animation;
    }
  }
}
.counts__text {
  flex: 1 0;
  line-height: (20/27);
  white-space: nowrap;
  margin-bottom: auto;
  font-weight: 300;
}


@keyframes line {
  0%{
    width: 0;
  }
  50%{
    width: 50%;
  }
  100%{
    width: 49px;
  }
}






// for pseudos
$thickness: 3px;
$length: rem(50);
$distance-of: rem(50);
$distance-on: rem(10);
@mixin pseudo-hidden {
    &::after,
    &::before {
        background: $theme_color;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s;
        @content;
    }
    &::before {
        width: $length;
        height: $thickness;
    }
    &::after {
        height: $length;
        width: $thickness;
    }
}
@mixin pseudo-visible {
    &::after,
    &::before {
        opacity: 1;
        visibility: visible;
        z-index: 100;
        @content;
    }
}
.news {
    display: flex;
    flex-flow: column;
    margin-bottom: rem(26);
    // text-align: center;
    .news__time{
      margin-bottom: 1em;
    }
    &:hover {
        .news__title h3{
          @include underline-hovered;
        }
    }
}


.news__title {
    font-size: rem(18);
    color: #212121;
    text-transform: uppercase;
    text-align: left;
    h3 {
        font-weight: 700;
        font-family: 'Calibri';
        margin-top: 0;
        margin-bottom: rem(0);
        position: relative;
        @include underline(1s);

        &:hover{
          color: $theme-color;
        }
    }
}
.news__image {
    margin: 0 0 rem(11);
    height: 163px;

    &:hover{
      img{
        box-shadow: 0 0 0 2px $theme-color;
      }
    }

    img{
      transition: all 0.3s;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
}
.news__text {
  font-size: rem(16);
  color: #3b3b3b;
  margin-bottom: 20px;
  line-height: (18/16);
}
.news__time{
  color: #aaaaaa;
  font-size: rem(15);
  display: block;
  text-align: center;
  margin-top: 1em;
  margin-bottom: 0;
}
.news__excerpt {
  color: #3999ae;
  font-size: rem(18);
  line-height: (18/16);
  margin-bottom: rem(12);
}
// .news__links {
//   margin: auto 0 0 0;
// }
