@import "_includs/_mixins.scss";

@mixin active-nav($color, $active-color) {
  color: $color;
  @include state{
    > a{
      color: $active-color;
    }
  }
  &--active{
    > a{
      color: $active-color;
    }
  }
}

.nav{
  width: 100%;
  position: absolute;
  left: 0;
  // right: 0;
  top: 0;
  font-size: rem(18);
  text-transform: uppercase;
  font-family: 'Univers';

  .logo{
    margin-right: rem(42);
  }

  .container{
    align-items: flex-start;
    @include media(tablet){
      flex-flow: row wrap;
      align-items: center;
    }
  }

  &--header{
    padding-top: rem(24);

    .logo{
      margin-right: rem(27);
    }

    .nav__item{
      margin-right: rem(21);
      @include active-nav(#fff, #212121);
      @include media(tablet){
        margin-right: 0;
      }
    }

    .sub-nav{
      @include media(tablet){
        color: #fff;
      }
    }
  }

  // &--sticky{
  //   position: absolute;
  //   left: 0;
  //   right: 0;
  //   transform: translateY(-100%);
  //   background-color: #fff;
  //   padding-top: rem(14);
  //   transition: all 0.2s;
  //   z-index: -1;
  //   opacity: 0;
  //   @include media(tablet){
  //     // padding-top: 0;
  //
  //     .logo{
  //       // padding-top: rem(0);
  //       // @include media(tablet){
  //       //
  //       // }
  //       @include media-custom(480px){
  //         margin-right: 0;
  //         min-width: rem(200);
  //       }
  //     }
  //   }
  //   .nav__buttons{
  //     margin-top: 9px;
  //   }
  //
  //   .nav__item{
  //     margin-top: rem(9);
  //     margin-right: rem(16);
  //     @include active-nav(#323232, $theme_color);
  //     @include media(tablet){
  //
  //       margin-right: 0;
  //     }
  //   }
  //
  //   &.sticked{
  //     // overflow: visible;
  //     // height: auto;
  //     // padding-top: rem(14);
  //     position: fixed;
  //     top: 0;
  //     opacity: 1;
  //     transform: translateY(0);
  //     box-shadow: 0 1px 2px 0 rgba(0,0,0,0.4);
  //     z-index: 100;
  //   }
  // }
}

// .nav-holder{
//   // overflow: hidden;
//   position: relative;
//   width: 100%;
// }

.nav__list{

  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-flow: row;
  align-items: flex-start;

  @include media(tablet){
    display: none;
  }
}
.nav__item{
  padding-bottom: rem(16);
  line-height: (23/18);
  position: relative;
  a{
      @include text_ellipsis;
  }

  &:hover{
    .sub-nav{
      opacity: 1;
      visibility: visible;
    }
  }
}

.sub-nav{
  position: absolute;
  top: 100%;
  left: 0;
  list-style: none;
  padding: 0;
  margin: 0;
  color: #9d9d9d;

  z-index: 100;
  transition: all 0.2s;

  opacity: 0;
  visibility: hidden;
}

.sub-nav__item{
  a{
    display: block;
    padding: rem(9) rem(20);
    background-color: rgba(#040404,0.81);
  }

  &:hover,
  &--active{
    a{
      color: #fff;
      background-color: rgba(#040404,0.6);
    }
  }

}
.nav__buttons{
  display: flex;
  margin-bottom: rem(16);
  margin-left: auto;

  @include media(tablet){
    margin-bottom: auto;
  }
  .nav--header &{
    color: #ffffff;
  }

  .search-block{
    align-self: flex-start;
  }
}

.lang{
  text-align: center;
  font-size: 18px;
  position: relative;
  font-family: 'Lato';
  font-weight: 100;
  text-transform: uppercase;
  margin: 3px 12px 0;
}
.lang__list{
  margin: 0;
  padding: 0;
  list-style: none;
  width: 30px;
  &:hover{
    .lang__item{
      display: block;
    }
  }
}
.lang__item{
  display: none;
  margin-bottom: 5px;
  &--active{
    display: block;
  }
}

.enter-text{
  color: #fff;
  background-color: lighten(desaturate(adjust-hue($theme-color, 5.8074), 46.2222), 13.5294);
  @include size(86px, 271px);
  position: absolute;
  top: calc(100% + 18px);
  right: -34px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  visibility: hidden;
  opacity: 0;

  &::after{
    content: '';
    position: absolute;
    right: 40px;
    top: -9px;
    @include size(0);
    border-top: none;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 9px solid lighten(desaturate(adjust-hue($theme-color, 5.8074), 46.2222), 13.5294);
  }
  span,
  strong{
    display: block
  }
  span{
    font-size: 22px;
    line-height: 25px;
  }
  strong{
    font-size: 33px;
    line-height: 25px;
    font-weight: 400;
  }

  .btn--enter:hover &{
    visibility: visible;
    opacity: 1;
  }
}


/////////////////////////
.nav{
  transition: background-color 0.3s;
  .head-logo-image{
    position: static;
  }
  .sticked-logo-image{
    position: absolute;
    opacity: 0;
    visibility: hidden;
  }
  &.fixed{
    transform: translateY(-100%);
    position: fixed;
    background-color: #fff;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.4);
    opacity: 0;
    padding-top: 14px;
    z-index: 1000;

    input[type=search].search-field{
      background-color: rgba(0,0,0,.8);
    }
    .btn--submit-search{
      background-color: rgba(0,100,139,.8);
      &:hover{
        background-color: rgba(0,100,139,.9);
      }
    }
    // .nav--header{
    //   padding-top: 14px;
    // }
    .logo{
      margin-right: 42px;
    }
    .head-logo-image{
      position: absolute;
      opacity: 0;
      visibility: hidden;
    }
    .sticked-logo-image{
      position: static;
      opacity: 1;
      visibility: visible;
    }
    .nav__list{
      margin-top: 10px;
    }
    .nav__buttons{
      margin-top: 9px;
    }
    .nav__item{
      color: #323232;
      margin-right: 15px;
      @include active-nav(#323232 ,$theme-color);
    }
    .nav__buttons{
      color: #323232;
    }
    &.shown{
      transform: translateY(0%);
      opacity: 1;
      transition: transform 0.3s, opacity 0.3s;
    }
  }
}
.btn--enter{
  display: none;
  .nav.fixed &{
    display: inline-block;
  }
}
.lang{
  .nav.fixed &{
    display: none;
  }
}
