@import "_includs/_mixins.scss";


input,
select,
textarea {
    border: none;
    background: none;
}
label {
    cursor: pointer;
    user-select: none;
}
//only vertical resize for textareas
textarea {
    resize: vertical;
}
//styles for disabled elements
[disabled],
button[disabled],
html input[disabled] {
    cursor: not-allowed;
    // opacity: 0.8;
    user-select: none;
}

input[type="text"],
input[type="tel"],
input[type="search"],
input[type="password"],
input[type="email"],
textarea,
select{
  background-color: #fff;
  width: 100%;
  color: inherit;
  font-size: 16px;
  font-family: 'Calibri';
  padding-left: 14px;
  border: none;
  box-sizing: border-box;
  .contacts__form &{
    border: 1px solid #a3a3a3;
  }
}
input[type="text"],
input[type="tel"],
input[type="search"],
input[type="password"],
input[type="email"],
select{
  height: 35px;
  line-height: 35px;
}
input[type="text"],
input[type="tel"],
input[type="email"],
input[type="password"],
input[type="search"],
textarea{
  @include placeholder{
    font: inherit;
    color: inherit;
  };
  @include placeholder--focus;
  .contacts__form &{
    @include placeholder{
      color: #5b5b5b;
      font-size: 17px;
    }
      @include placeholder--focus;
  }
}
select{
  padding: 0;

  .display-count &{
    border-radius: 3px;
    border: 1px solid #bfbfbf;
  }
}
textarea{
  padding: 14px;

}

input[type="submit"]{
  transition: all 0.3s;
}

input[type="search"]{
  &.search-field{
    color: #fff;
    @include placeholder{
      color: #fff;
    };
    @include placeholder--focus;
    background-color: rgba(#000, 0.41);
    height: 39px;
    line-height: 39px;
    padding: 0 0 0 10px;
    width: 263px;

    @include media-custom(480px){
      width: 230px;
    };
  }
}

.radio-hidden{
  position: absolute;
  opacity: 0;
}

.search-block{
  position: relative;
  input[type="search"]{
    border: none;
    height: 39px;
  }
}
.search{
  position: absolute;
  right: 0;
  top: calc(100% + 9px);
  transition: all 0.3s;
  transform: translateY(0%);
  z-index: 100000;
  @include media-custom(480px){
    right: -70px;
  };

  &.hidden{
    transform: translateY(100%);
    opacity: 0;
    visibility: hidden;

  }
}
.header-form{
  display: flex;

  .btn{
    margin-left: 5px;
  }
}

.display-count-select{
  width: 58px;
  height: 40px;
  text-align: center;
  color: #323232;
  font-size: 17px;
  font-family: 'Univers';
  font-weight: 700;
  padding-left: 5px;
  appearance: none;
  option{
    padding-left: 5px;
  }
}

.ask__inner{
  input,
  textarea{
    margin: 5.5px 0;
    border: none;
  }

  input[type="text"],
  input[type="email"]{
    height: 39px;
  }

  input[type="submit"]{
    margin-top: 12px;
    display: inline-block;
    border: none;
    background: none;
    transition: background-color 0.3s;
    background-color: lighten(desaturate(adjust-hue($theme-color, 5.8074), 46.2222), 13.5294);
    font-weight: 700;
    font-family: 'Univers';
    color: #fff;
    text-transform: uppercase;
    border-radius: 5px;
    font-size: 19px;
    padding: ((35-19)/2)+px 31px;

    &:hover{
      background-color: darken($theme-color, 10%);
    }
  }
  textarea{
    height: 110px;
  }
}

.has-error{
  .help-block{
    color: red;
  }
  input,textarea,input[type=text]{
    border-color: red;
  }
}
