@import "_includs/_mixins.scss";

.slider{
  position: relative;
  width: 100%;
  // overflow: hidden;
  &--clients{
    padding: 0 77px;
    @include media(tel){
      padding: 0 55px;
    };
    img{
      vertical-align: bottom;
    }
  }
  &--mp-header{
    color: #fff;
    margin: auto;
    p{
      // font-size: rem(47);
      line-height: 1.2;
      font-weight: 300;
      text-transform: uppercase;
      @include media(tablet){
        font-size: rem(30);
      }
    }
    .item{
      text-align: center;
    }
  }
}

.slick-slide{
  outline: none;
}

.slick-arrow{
  position: absolute;  top: 0;
  outline: none;
  background: none;
  margin: 0;
  padding: 0;
  border: none;
  overflow: hidden;
  text-indent: -99999px;
  transition: opacity 0.3s;
  z-index: 10;
  opacity: 0.7;
  transform-origin: 50% 50%;


  &:hover{
    opacity: 1;
  }

  .slider--clients &,
  .slider-related &,
  .slider-propose &,
  .customers-slider &,
  .other-news-slider &,
  .slider--mp-header &{
    top: 50%;
    transform: translateY(-50%);

    @include media(tel){
      transform: scale(0.5) translateY(-50%);
    };
  }
}
.slick-next {
  right: 0;
  @include sprite($right);
  .slider-related &{
    @include sprite($right-white);
    right: 15px;
  }
}
.slick-prev {
  left: 0;
  @include sprite($left);
  .slider-related &{
    @include sprite($left-white);
    left: 15px;
  }
}

.slider--clients{
  .slick-slide{
    min-height: 280px;
    @include media(tel){
      min-height: none;
    };
    > div{
      // min-height: 140px;
      // @include media(tel){
      //   min-height: none;
      // };
      display: flex;
      // align-items: center;

      &:first-child{
        margin-bottom: 10px;
      }
    }
  }
}

.slider--clients,
.slider-related{
  .slick-next,
  .slick-prev{
    @include media(tel){
      transform: scale(0.5) translateY(-100%);
    };
  }
}




.team-slider{
  width: 100%;
  overflow: hidden;
  max-height: 395px;
  @include media(tel){
  max-height: none;
  };
  padding: 0 73px;
  position: relative;
  .slick-arrow{
    top: auto;
    bottom: 12px;
  }
  .slick-slide{
    width: 153px;
    @include media(tel){
      width: auto;
    }
    transition: all 0.3s;
      img{
        width: 153px;
        @include media(tel){
          width: 293px;
        }
      }

      // &.centerSlide{
      //   width: 323px;
      //   .member__info{
      //     opacity: 1;
      //   }
      //   img{
      //     width: 293px;
      //     max-width: none;
      //   }
      // }
  }

  .slick-current + .slick-active + .slick-active {
    width: 293px;
    @include media-custom(1024px){
      width: 153px;
    }
    .member__info{
      opacity: 1;
      @include media-custom(1024px){
        opacity: 0;
      }
    }
    img{
      width: 293px;
      max-width: none;
      @include media-custom(1024px){
        width: 153px;
      }

    }
  }
  .slick-current  {
    @include media-custom(1024px){
      width: 293px;
      .member__info{
        opacity: 1;
      }
      img{
        width: 293px;
        max-width: none;
      }
    };

  }
}





.customers-slider{
  width: 100%;
  overflow: hidden;
  padding: 0 90px;
  @include media(tel){
    padding: 0 55px;
  };
}

.other-news-slider{
  padding: 0 118/1200*100%;
}


.propose{
  margin-bottom: 70px;
  .s-header{
    padding-top: 22px;
  }
}
.slider-propose{
    padding: 0 92/1200*100%;
    margin-top: 58px;

    img{
      margin: auto;
      max-width: 217px;

    }
}

.propose__item{
  text-align: center;
}
.propose__image{
  min-height: 214px;
  display: block;
}
.propose__text{
  font-family: 'Univers';
  display: block;
  text-align: center;
  margin-top: 15px;
  font-size: 19px;
  color: #323232;
  transition: all 0.3s;
  .propose__item:hover &{
    color: $theme-color;
  }
}
