@import "_components/_includs/_mixins.scss";

.search-out-item {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  width: 100%;
  // margin-bottom: 45/1200*100%;
  &:nth-child(odd){
    background-color: #f7f7f7;
  }
  &:last-child{
  margin-bottom: 60/1200*100%;
  }
  padding: 46/1200*100% 32/1200*100% 31/1200*100% 21/1200*100%;
  @include media(tel){
    flex-wrap: wrap;
  };
  .link {
    margin-top: 20px;
    &--right{
      margin-top: 0;
      @include media(tablet){
        margin-top: 20px;
      };
    }
    .btn--accent {
      &:hover{
        border-color: $theme-color;
      }
    }
  }
}

.search-out-item__left-col {
  flex-shrink: 0;
  margin-right: 20px;
  width: 340px;
  @include media(tel){
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    margin-right: 0;
  };


}

.search-out-item__image {
  min-width: 340px;
  @include media(tel){
    min-width: auto;
  };
}





.search-out-item__right-col {

}

.search-out-item__name {
  color: #212121;
  margin: 0 0 25px;
  font-family: 'Univers';
  font-size: 40px;
  @include media(tel){
    text-align: center;
    font-size: 30px;
  };
  span{
    display: inline-block;
    border-bottom: 1px solid $theme-color;
    padding-bottom: 20px;
  }
}

.search-out-item__info {
  display: flex;
  justify-content: space-between;
  @include media(tablet){
    flex-wrap: wrap;
  };

  &--plan{
    align-items: center;
  }
}

.search-out-item__details {
  width: 436/788*100%;
  @include media(tablet){
    width: 100%;
  };
}


.pattern-link {
  text-align: right;
  @include media(tablet){
    width: 100%;
    text-align: left;
    margin-top: 15px;
  };
  @include media(tel){
    text-align: center;
  };
  .btn{
    padding: (53-19)/2+px 58px (53-19)/2+px 16px;
    background-color: #fcfcfc;
    @include media-custom(400px){
      font-size: 15px;
    };
  }
  .icon {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    display: inline-block;
    &--download-black{
      @include sprite($download-black);

    }
  }

}



.search-out-item__excerpt {
  margin: 22px 0px;
  p{
    margin: 0;
  }
}
