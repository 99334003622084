.mfp-bg,
.mfp-wrap {
    position: fixed;
    left: 0;
    top: 0
}
.mfp-bg,
.mfp-container,
.mfp-wrap {
    height: 100%;
    width: 100%
}
.mfp-container:before,
.mfp-figure:after {
    content: ''
}



.mfp-wrap {
    z-index: 1043;
    outline: 0!important;
    -webkit-backface-visibility: hidden
}
.mfp-container {
    text-align: center;
    position: absolute;
    left: 0;
    top: 0;
    padding: 0 8px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}
.mfp-container:before {
    display: inline-block;
    height: 100%;
    vertical-align: middle
}
.mfp-align-top .mfp-container:before {
    display: none
}
.mfp-content {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: 0 auto;
    text-align: left;
    z-index: 1045
}
.mfp-ajax-holder .mfp-content,
.mfp-inline-holder .mfp-content {
    width: 100%;
    cursor: auto
}
.mfp-ajax-cur {
    cursor: progress
}
.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
    // cursor: -moz-zoom-out;
    // cursor: -webkit-zoom-out;
    // cursor: zoom-out
    cursor: pointer;
}
.mfp-zoom {
    cursor: pointer;
    cursor: -webkit-zoom-in;
    cursor: -moz-zoom-in;
    cursor: zoom-in
}
.mfp-auto-cursor .mfp-content {
    cursor: auto
}
.mfp-arrow,
.mfp-close,
.mfp-counter,
.mfp-preloader {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}
.mfp-loading.mfp-figure {
    display: none
}
.mfp-hide {
    display: none!important
}
.mfp-preloader {
    color: #ccc;
    position: absolute;
    top: 50%;
    width: auto;
    text-align: center;
    margin-top: -.8em;
    left: 8px;
    right: 8px;
    z-index: 1044
}
.mfp-preloader a {
    color: #ccc
}
.mfp-close,
.mfp-preloader a:hover {
    color: #fff
}
.mfp-s-error .mfp-content,
.mfp-s-ready .mfp-preloader {
    display: none
}
button.mfp-arrow,
button.mfp-close {
    overflow: visible;
    cursor: pointer;
    background: 0 0;
    border: 0;
    -webkit-appearance: none;
    display: block;
    outline: 0;
    padding: 0;
    z-index: 1046;
    -webkit-box-shadow: none;
    box-shadow: none
}
.mfp-figure:after,
.mfp-iframe-scaler iframe {
    box-shadow: 0 0 8px rgba(0, 0, 0, .6);
    position: absolute;
    left: 0
}
button::-moz-focus-inner {
    padding: 0;
    border: 0
}
.mfp-close {
    width: 44px;
    height: 44px;
    line-height: 44px;
    position: absolute;
    right: 0;
    top: 0;
    text-decoration: none;
    text-align: center;
    opacity: .65;
    padding: 0 0 18px 10px;
    font-style: normal;
    font-size: 28px;
    font-family: Arial, Baskerville, monospace
}
.mfp-close:focus,
.mfp-close:hover {
    opacity: 1
}
.mfp-close:active {
    top: 1px
}
.mfp-close-btn-in .mfp-close {
    color: #333
}
.mfp-iframe-holder .mfp-close,
.mfp-image-holder .mfp-close {
    color: #fff;
    right: -6px;
    text-align: right;
    padding-right: 6px;
    width: 100%
}
.mfp-counter {
    position: absolute;
    top: 0;
    right: 0;
    color: #ccc;
    font-size: 12px;
    line-height: 18px
}
.mfp-figure,
img.mfp-img {
    line-height: 0
}
.mfp-arrow {
    position: absolute;
    opacity: .65;
    margin: -55px 0 0;
    top: 50%;
    padding: 0;
    width: 90px;
    height: 110px;
    -webkit-tap-highlight-color: transparent
}
.mfp-arrow:active {
    margin-top: -54px
}
.mfp-arrow:focus,
.mfp-arrow:hover {
    opacity: 1
}
.mfp-arrow .mfp-a,
.mfp-arrow .mfp-b,
.mfp-arrow:after,
.mfp-arrow:before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: inset transparent
}
.mfp-arrow .mfp-a,
.mfp-arrow:after {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px
}
.mfp-arrow .mfp-b,
.mfp-arrow:before {
    border-top-width: 21px;
    border-bottom-width: 21px
}
.mfp-arrow-left {
    left: 0
}
.mfp-arrow-left .mfp-a,
.mfp-arrow-left:after {
    border-right: 17px solid #fff;
    margin-left: 31px
}
.mfp-arrow-left .mfp-b,
.mfp-arrow-left:before {
    margin-left: 25px;
    border-right: 27px solid #3f3f3f
}
.mfp-arrow-right {
    right: 0
}
.mfp-arrow-right .mfp-a,
.mfp-arrow-right:after {
    border-left: 17px solid #fff;
    margin-left: 39px
}
.mfp-arrow-right .mfp-b,
.mfp-arrow-right:before {
    border-left: 27px solid #3f3f3f
}
.mfp-iframe-holder {
    padding-top: 40px;
    padding-bottom: 40px
}
.mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px
}
.mfp-image-holder .mfp-content,
img.mfp-img {
    max-width: 100%
}
.mfp-iframe-holder .mfp-close {
    top: -40px
}
.mfp-iframe-scaler {
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-top: 56.25%
}
.mfp-iframe-scaler iframe {
    display: block;
    top: 0;
    width: 100%;
    height: 100%;
    background: #000
}
.mfp-figure:after,
img.mfp-img {
    width: auto;
    height: auto;
    display: block
}
img.mfp-img {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 40px 0;
    margin: 0 auto
}

.mfp-figure small {
    color: #bdbdbd;
    display: block;
    font-size: 12px;
    line-height: 14px
}
.mfp-bottom-bar {
    margin-top: -36px;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    cursor: auto
}
.mfp-title {
    text-align: left;
    line-height: 18px;
    color: #f3f3f3;
    word-wrap: break-word;
    padding-right: 36px
}
.mfp-gallery .mfp-image-holder .mfp-figure {
    cursor: pointer
}
@media screen and (max-width: 800px) and (orientation: landscape),
screen and (max-height: 300px) {
    .mfp-img-mobile .mfp-image-holder {
        padding-left: 0;
        padding-right: 0
    }
    .mfp-img-mobile img.mfp-img {
        padding: 0
    }
    .mfp-img-mobile .mfp-figure:after {
        top: 0;
        bottom: 0
    }
    .mfp-img-mobile .mfp-figure small {
        display: inline;
        margin-left: 5px
    }
    .mfp-img-mobile .mfp-bottom-bar {
        background: rgba(0, 0, 0, .6);
        bottom: 0;
        margin: 0;
        top: auto;
        padding: 3px 5px;
        position: fixed;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box
    }
    .mfp-img-mobile .mfp-bottom-bar:empty {
        padding: 0
    }
    .mfp-img-mobile .mfp-counter {
        right: 5px;
        top: 3px
    }
    .mfp-img-mobile .mfp-close {
        top: 0;
        right: 0;
        width: 35px;
        height: 35px;
        line-height: 35px;
        background: rgba(0, 0, 0, 0);
        position: fixed;
        text-align: center;
        padding: 0
    }
}
@media all and (max-width: 900px) {
    .mfp-arrow {
        -webkit-transform: scale(.75);
        transform: scale(.75)
    }
    .mfp-arrow-left {
        -webkit-transform-origin: 0;
        transform-origin: 0
    }
    .mfp-arrow-right {
        -webkit-transform-origin: 100%;
        transform-origin: 100%
    }
    .mfp-container {
        padding-left: 6px;
        padding-right: 6px
    }
}
.mfp-ie7 .mfp-img {
    padding: 0
}
.mfp-ie7 .mfp-bottom-bar {
    width: 600px;
    left: 50%;
    margin-left: -300px;
    margin-top: 5px;
    padding-bottom: 5px
}
.mfp-ie7 .mfp-container {
    padding: 0
}
.mfp-ie7 .mfp-content {
    padding-top: 44px
}
.mfp-ie7 .mfp-close {
    top: 0;
    right: 0;
    padding-top: 0
}

// CUSTOMIZE //
