@import "_includs/_mixins.scss";

.pagination{
  width: 100%;
  .press-center &{
    margin: 13px auto 0;
  }
  .pag-wrap &{
    margin: 33px auto 0;
  }
}
.pagination__list{
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  @include media(tel){
    flex-wrap: wrap;
  };

  .cabinet &{
    margin-top: 30px;
    justify-content: center;
  }
}
.pagination__item{
  font-family: 'Univers';
  font-size: 17px;
  color: #323232;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  @include media(tel){
    margin-bottom: 8px;
  };
  &:not(:last-child){
    margin-right: 8px;
  }
  &.active{
    a,span{
      color: #fff;
      background-color: $theme-color;
      border-color: $theme-color;
    }
  }
  &:hover{
    a{
      color: #fff;
      background-color: $theme-color;
      border-color: $theme-color;
    }
  }
  a,span{
    display: block;
    padding: ((39-17)/2+px) 0;
    border-radius: 3px;
    border: 1px solid #bfbfbf;
    width: 40px;
  }
  &:last-child,
  &:first-child{
    a,span{
      width: auto;
      padding-left: 23px;
      padding-right: 23px;
    }
  }
}
