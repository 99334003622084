@import "_includs/_mixins.scss";

.related{
  padding-bottom: 76px;
  .s-header{
    margin-bottom: 55px;
  }
}
.slider-related__item{
  .holder__image{
    @include media(tel){
      min-height: 320px;
    };
  }
  .holder__info{
    opacity: 1;
    padding-left: 15%;
    padding-right: 15%;
  }
  .holder__link{
    opacity: 0;
    @include media(tablet){
      opacity: 1;
    };
  }
  .holder__title{
    font-family: 'Univers';
    line-height: (25/24);
    margin-bottom: 25px;
  }
  &:hover{
    .holder__link{
      opacity: 1;
    }
  }
}
