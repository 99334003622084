@import "_components/_includs/_mixins.scss";
.base{
  padding-bottom: 40px;
  .container{
    flex-wrap: wrap;
  }
}
.base-search{
  width: 100%;
  margin-bottom: 36px;
}
.base-search__wrap{
  width: 100%;
  // display: flex;
  // flex-wrap: wrap;
  .form-group {
    margin-bottom: 10px;
    padding-top: 10px;
  }
}
.base-search__row{
  width: 100%;
  display: flex;
  justify-content: space-between;
  @include media(tel){
    flex-wrap: wrap;
  };
}
.base-choose{
  width: 100%;
  font-size: 25px;
  @include media-custom(1024px){
    font-size: 20px;
  };
  @include media(tel){
    justify-content: space-between;
  };
  padding-bottom: 10px;
  justify-content: flex-start;
}
.base-choose__item{
  margin-bottom: 15/1200*100%;
  @include media(tel){
    width: calc(50% - 5px);
  };
  @include media-custom(550px){
    width: 100%;
  };
  text-align: center;
  &:not(:last-child){
    margin-right: 15/1200*100%;
    @include media(tel){
      margin-right: 0;
    };
  }
  input[type="radio"]:checked{
    + .base-choose__label{
      background-color: #636363;
    }
  }
}
.base-choose__label{
  display: block;
  color: #fff;
  font-family: 'Univers';
  text-transform: uppercase;
  background-color: #c6c6c6;
  padding: em(((66-25)/2), 25) em(20,25);
  border-radius: 5px;
  transition: all 0.3s;
}

.base-selects{
  background-color: $theme-color;
  padding: 10/1200*100% 20/1200*100%;
  .btn--submit-base-search{
    font-size: 32px;
    padding: em(((52-32)/2),32) em(43,32);
    @include media(tel){
      font-size: 25px;
    };
  }
  .submit-wrap{
    text-align: right;
    padding-top: 5px;
    @include media(tel){
      text-align: center;
    };
  }
}
.base-downloads{
  background-color: #28acbe;
  border-top: 1px solid  #6cc3cf;
  justify-content: flex-end;
  font-size: 19px;
  padding: em(((49-29)/2), 19) 0;
  @include media(tel){
    flex-direction: column;
  };

  .btn{
    margin-right: auto;
    margin-left: 22px;
    @include media(tel){
      margin-left: auto;
      margin-bottom: 20px;
    };
  }
}
.base-downloads__list{
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  @include media(tel){
    flex-direction: column;
  };
  li{
    color: #fff;
    font-weight: 700;
    font-family: 'Univers';
    font-size: 1em;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    padding: 0 em(20,19);

    &:not(:last-child){
      border-right: 1px solid #2e3f42;
      @include media(tel){
        border: none;
        margin-bottom: 15px;
      };
    }
  }
  a{
    &:hover{
      text-decoration: underline;
    }
  }
  .icon{
    margin-right: 15px;
    @include sprite($download);
  }
}
.base-selects__left-col{
  width: 656/(1200-21*2)*100%;
  margin-bottom: 5px;
  @include media(tablet){
    width: calc(50% - 10px);
  };
  @include media(tel){
    width: 100%;
  };
}
.base-selects__right-col{
  width: 470/(1200-21*2)*100%;
  margin-bottom: 5px;
  @include media(tablet){
    width: calc(50% - 10px);
  };
  @include media(tel){
    width: 100%;
  };
}

.form-group--row{
  display: flex;
  @include media(tablet){
    flex-wrap: wrap;
  };
}
.countries{
  display: flex;
  justify-content: space-between;
  width: 398/656*100%;
  @include media(tablet){
    width: 100%;
    margin-bottom: 20px;
  };
}
.countries__item{
  width: calc(#{(100/3*1%)} - 3.3px);
  @include media(tablet){
    margin-bottom: 15px;
  };
  input[type="radio"]:checked{
    + .country-label{
      background-color: #09363c;
    }
  }
}
.country-label{
  text-align: center;
  display: block;
  color: #fff;
  background-color: #4b6d72;
  text-transform: uppercase;
  font-family: 'Univers';
  font-size: 19px;
  padding: 8px 5px;
  transition: all 0.3s;
  border-radius: 5px;
}
.from-to{
    width: 258/656*100%;
    min-width: 230px;
    max-width: 270px;
    display: flex;
    flex-shrink: 0;
    @include media(tel){
      margin: auto;
    };
}
.from-to__item{
  width: 50%;
  display: flex;
  align-items: center;

  select,
  .select2{
    max-width: 95px;
  }

  em{
    font-style: normal;
    font-size: 19px;
    color: #fff;
    font-family: 'Univers';
    margin: auto;
  }
}

.show-last{
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  @include media(tel){
    flex-direction: column;
    justify-content: center;
    align-items: center;
  };
  select,
  .select2{
    max-width: 238px;
  }
  em{
    text-transform: uppercase;
    font-style: normal;
    font-size: 19px;
    color: #fff;
    font-family: 'Univers';
    white-space: nowrap;
    margin-right: 43/656*100%;
    @include media(tel){
      text-align: center;
      margin-bottom: 30px;
      margin-right: 0;
    };
  }
}

.base-categories {

}

.base-categories__item {
  width: 100%;
}

.base-category {
  margin-bottom: 18px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.base-category__over {
    background: rgba(#eeeeee, 0.85);
    display: flex;
    justify-content: space-between;
    min-height: 122px;

    @include media(tel){
      flex-direction: column;
    };
}

.overview-counts {
  background-color: rgba(#1b1b1b, 0.75);
  color: #fff;
  font-size: 25px;
  text-transform: uppercase;
  font-family: 'Univers';
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 116px;
  flex-shrink: 0;
  @include media(tel){
    flex-direction: row;
    width: 100%;
    height: 55px;
  };
  p{
    font-size: 25px;
    margin: 0;
    margin-left: 10px;
  }
}

.base-category__name {
  color: #212121;
  font-family: 'Univers';
  font-size: 40px;
  align-self: center;
  padding-left: 15px;
  width: 524/1200*100%;
  padding-right: 5px;
  margin: 20px 0;
  @include media(tel){
    width: 100%;
    padding-right: 15px;
    text-align: center;
    font-size: 30px;
  };
  a{
    position: relative;
    @include underline(0.3s);
    &:hover{
      @include underline-hovered;
      color: $theme-color;
    }
  }
}

.base-category__tags {
  width: 561/1200*100%;
  align-self: center;
  font-size: 16px;
  color: #1c1c1c;
  line-height: (20/16);
  margin: 20px 0;
  padding-right: 15px;

  a{
    &:hover{
      text-decoration: underline;
      color: $theme-color;
    }
  }
  @include media(tel){
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-top: 0;
  };
}
.base-search-results{
  width: 100%;
}
.base-search-results__item {
		width: 100%;
    margin-bottom: 9px;
}

.base-search-result {
  padding: 16px 33px;
	background-color: #f1f1f1;
  display: flex;
  justify-content: space-between;
  @include media(tel){
    flex-wrap:wrap;
    padding: 16px 16px;
  };

  &:hover{
    .base-search-result__link{
      color: $theme-color;
    }
  }
}

.base-search-result__title {
  width: 381/(1200-33*2)*100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include media(tel){
    width: 100%;
  };
  .btn {
      margin-top: 22px;
      @include media(tel){
          margin-bottom: 22px;
      };
      &:hover{
        border-color: $theme-color;
      }
  }

  .wrap{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    .tag{
      font-family: 'Univers';
      font-size: 40px;
      margin-top: 12px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      @include media(tel){
        font-size: 25px;
        margin-bottom: 12px;
      };
    }
  }
}

.base-search-result__link{
  font-family: 'Univers';
  font-size: 37px;
  color: #212121;
  @include media(tel){
    font-size: 25px;
    margin-bottom: 15px;
  }
}

.base-search-result__text {
  width: 713/(1200-33*2)*100%;
  font-size: 16px;
  color: #1c1c1c;
  line-height: (20/16);

  @include media(tel){
    width: 100%;
  };
  p{
    margin: 0;
    font-size: 16px;
    line-height: (20/16);
  }
  .wrp{
    padding-top: 15px;
  }
}
