@import "_includs/_mixins.scss";
.offer{
  padding-bottom: 52px;
  .s-header{
    margin-bottom: 72px;
    padding-top: 40px;
  }
  .container{
    @include media(tel){
      flex-wrap: wrap;
    };
  }
}
.offer__item{
  width: 379/1200*100%;
  margin-bottom: 30px;
  @include media(tel){
    width: 100%;
  };
  .holder__image{
    height: 295px;
  }
  .holder__title{
    font-size: 24px;
    font-family: 'Univers';
    margin-bottom: 25px;
  }
}
