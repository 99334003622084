@import "_includs/_mixins.scss";

.breadcrumbs{
	margin-top: 13px;
	padding-bottom: 30px;
	overflow: hidden;

	.contacts &{
		margin-top: 0;
		padding-top: 13px;
		margin-bottom: 70px;
	}
	ul{
		margin: 0;
		padding: 0;
		list-style: none;
		font-size: 17px;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		border-left: 1px solid #eee;
	}
	a{
		position: relative;
		// padding-left: 21px;
		color: #5b5b5b;
		display: block;
		padding: 11px 0 11px 21px;
		border-top: 1px solid #eee;
		border-bottom: 1px solid #eee;

		// &::before,
		&::after{
			content: '';
			position: absolute;
			top: -1px;
			left: 100%;
			width: 18px;
			height: 41px;
			@include sprite($q);
			// background-color: rgba(#536d87, 0.19);
			// height: 1px;
			// width: 25px;
			// right: -16px;
			// top: 50%;
			// margin-top: -1px;
			// transform-origin: 100% 50%;
		}
		&::before{
			// transform: rotate(50deg);

		}
		&::after{
			// transform: rotate(-50deg);
		}

		&:hover{
			color: #242424;
		}
	}
	li{
	//  margin-bottom: 5px;
		background-color: #fff;
		&:last-child{
			background-color:#eee;
			padding-right: 0;
			a{
				color: #242424;
				&::after{
					@include sprite($q1);
				}
			}
		}
		// &:first-child{
		// 	border-left: 1px solid rgba(#536d87, 0.19);
		// }
	}
}
