@import "_includs/_mixins.scss";

.container{
  $max-width: rem($template-container);
  $width: 96%;
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: $max-width;
  width: $width;

  .footer > &{
    @include media(tablet){
      flex-flow: column;
    }
  }
}

//full width with hover images
.full{
  width: 100%;
  display: flex;
  justify-content: space-between;

  @include media(tel){
    flex-flow: column;
  }
}
.full__item{
  flex: 1 1;
}
