@import "_components/_includs/_mixins.scss";

.cabinet{
  padding-top: 64px;
  padding-bottom: 72px;
}
.cabinet-tabs{
  width: 100%;
}
.cabinet-tabs-caption{
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  width: 100%;
  border-bottom: 13px solid $theme-color;
  @include media-custom(480px){
    flex-wrap: wrap;
    border-bottom: none;
  };
}
.cabinet-tabs-caption__item{
  position: relative;
  color: #262626;
  font-family: 'Univers';
  font-size: 19px;
  text-transform: uppercase;
  &:not(:last-child){
    margin-right: 8px;
    @include media-custom(480px){
      margin-right: 0;
    };
  }
  @include media-custom(480px){
    width: 100%;

    &:last-child{
      a{
        border-bottom: 1px solid #bfbfbf;
      }
    }
  };

  &::after{
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -23px;
    width: 0;
    height: 0;
    color: $theme-color;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 10px solid currentColor;
    opacity: 0;
    transition: all 0.3s;
    @include media-custom(480px){
      color: #fff;
      bottom: auto;
      top: 50%;
      left: auto;
      right: 0;
      transform: translateY(-50%);
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-right: 10px solid currentColor;
    };
  }
  a{
    display: block;
    text-align: center;
    border: 1px solid #bfbfbf;
    border-bottom: none;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 18px 48px 12px;
    @include media(tel){
      padding: 12px 12px 12px;
    };
    @include media-custom(480px){
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    };

  }
  &.active,
  &:hover{
    &::after{
      opacity: 1;
    }
    a{
      border-color: $theme-color;
      color: #fff;
      background-color: $theme-color;
    }
  }
}
.cabinet-tab{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 57/1200*100% 35px;

  background-color: #efefef;
  &.hidden{
    display: none;
  }
}
.cabinet-tab__title{
  margin-top: 47px;
  margin-bottom: 0;
  padding-bottom: 37px;
  font-family: 'Univers';
  color: #252525;
  font-size: 38px;
  width: 100%;
  text-align: center;
}
.promotion{
  display: flex;
  width: 100%;
  max-width: 1009px;
  margin: 0 auto 20px;
  &:last-child{
    margin-bottom: 45px;
  }
  @include media-custom(480px){
    flex-wrap: wrap;
  };
}
.promotion__image{
  width: 151px;
  margin-right: 21px;
  flex-shrink: 0;
  @include media-custom(480px){
    width: 100%;
    margin-right: 0;
    text-align: center;
    margin-bottom: 15px;
  };

  img{
    vertical-align: bottom;
  }
}
.promotion__content{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  .link{
    margin-top: auto;
    @include media-custom(480px){
      margin-top: 20px;
      margin-bottom: 20px;
    };
  }
  .btn{
    &:hover{
      border-color: $theme-color;
    }
  }
}
.promotion__name{
  width: 100%;
  font-weight: bold;
  font-size: 18px;
  color: #4a4a4a;

  a{
    position: relative;
    @include underline(0.3s);
    &:hover{
        @include underline-hovered;
    }
  }
}
.promotion__text{
  width: 100%;
  font-size: 17px;
  color: #626262;
}
.bills{
  width: 100%;
  max-width: 1027px;
  margin: 0 auto;
  margin-bottom: 40px;
}
.cabinet-table{
  width: 100%;
  text-align: center;
  table-layout: fixed;
  thead{
    font-size: 19px;
    text-transform: uppercase;
    font-family: 'Univers';
    color: #404040;
  }
  tr{
    // transition: background-color 0.3s;
    &:nth-child(odd){
      background-color: #f7f7f7;
      // &:hover{
      //   background-color: #dcdcdc;
      // }
    }
    &:nth-child(even){
      background-color: #dcdcdc;
      // &:hover{
      //   background-color: #f7f7f7;
      // }
    }
  }
  td,th{
    border: 1px solid #a5a5a5;
    padding-left: 10px;
    padding-right: 10px;
  }
  td{
    font-size: 16px;
    color: #3b3b3b;
    padding-top: 25px;
    padding-bottom: 25px;
    &:nth-child(2){
      text-align: left;
      padding-left: 15px;
    }
  }
  th{
    font-weight: normal;
    background-color: #fff;
    padding-top: 28px;
    padding-bottom: 28px;

    &:first-child{
      width: 98px;
    }
    &:last-child{
      width: 296/1026*100%;
    }
  }
}

.basic{
  padding-top: 47px;
  width: 100%;
  max-width: 1025px;
  display: flex;
  justify-content: space-between;
  position: relative;
  @include media(tel){
    flex-wrap: wrap;
  };
}
.basic__left-col{
  width: 410/(1025-57*2)*100%;
  .basic__title{
    text-align: center;
  }
  @include media-custom(1024px){
    width: calc(50% - 10px);
  };
  @include media(tel){
    width: 100%;
  };
}
.basic__center-col {
	  display: flex;
    align-items: flex-end;
    @include media-custom(1024px){
      position: absolute;
      right: 0;
      bottom: 0;
    };
    @include media(tel){
      right: 50%;
      transform: translateX(50%);
    };
    .btn {
      white-space: nowrap;
    }
    .a-count{
      font-size: 27px;
    }
    .a-text{
      font-size: 23px;
      margin-bottom: 0;
    }
}
.basic__right-col{
  @include media-custom(1024px){
    width: calc(50% - 10px);
    padding-bottom: 100px;
  };
  @include media(tel){
    width: 100%;
    text-align: center;
    margin-top: 20px;
  };
}
.basic__title{
  color: #3da2b0;
  font-family: 'Univers';
  font-size: 38px;
  margin-bottom: 30px;
  @include media(tel){
  text-align: center;
  };
}

.user{

}
.user-form{

}
.user-form__list{
  margin: 0;
  padding: 0;
  list-style: none;

}
.user-form__item{
  display: flex;
  align-items: center;
  margin-bottom: 4px;

  .form-group{
    width: 100%;
  }

  &--pass-confirm{
    &.hidden{
      display: none;
    }
  }
}

.user-form__item-select{
  .select2-container--default .select2-selection--single,
  .select2-container--default .select2-selection--single .select2-selection__arrow{
    height: 28px;
    outline: none;

  }
  .select2-container--default .select2-selection--single .select2-selection__rendered{
    line-height: 28px;
    font-size: 17px;
    padding-left: 15px;
  }
  .select2-container--disabled .select2-selection__arrow{
    display: none;
  }
  .select2-container--default .select2-selection--single .select2-selection__arrow b{
    border-width: 4px 3px 0;
  }
  .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b{
    border-width: 0 3px 4px;
  }
}
.user-form__label{
  font-size: 18px;
  font-weight: 700;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex-shrink: 0;
  text-align: right;
  width: 159/410*100%;
  min-width: 100px;
  margin-right: 40/410*100%;
  @include media(tel){
    margin-right: 10px;
    width: 25%;
  };
}
.user-form__input{
  &[disabled]{
    background: none;
    cursor: default;
  }
}
input[type='text'].user-form__input,
input[type='tel'].user-form__input,
input[type='email'].user-form__input,
input[type='password'].user-form__input{
  font-size: 17px;
  color: #626262;
  height: 28px;
  line-height: 28px;
}

.user-form__btn-wrap{
  text-align: center;
  margin-top: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
}



.basic__right-col {

}

.subscr{
  padding: 0;
  margin: 0;
  list-style: none;
  .extend-subscr &{
    padding-bottom: 7px;
  }
  li{
    margin-bottom: 12px;
  }
}

.subscr__link {
  color: #fff;
  text-transform: uppercase;
  display: inline-block;
  font-family: 'Univers';
}
a.subscr__link {
  font-size: 19px;
  background-color: rgba(0,0,0,.61);
  padding: (44-19)/2+px 8px;
  &:hover{
    background-color: rgba(0,0,0,1);
  }
}
span.subscr__link {
  font-size: 23px;
  background-color: #28acbe;
  padding: (44-23)/2+px 8px;

  &.hidden{
    display: none;
  }
}
.change-sections-select{
  &.hidden{
    display: none;
    + .select2{
      display: none;
    }
  }


}

.all-sections{
  font-size: 30px;
  text-align: center;
  font-family: 'Univers';
  color: #242424;
  display: none;
}

.subscr-time {
  font-size: 17px;
  color: #626262;
  // text-align: center;
}

.subscr-time__deadline {
  display: block;
  font-size: 35px;
  padding-top: 11px;
  font-weight: 700;
}

.subscr__change {
  margin-top: 45px;
  margin-bottom: 16px;
}

.subscr__change-link {
  font-size: 28px;
  font-family: 'Univers';
  color: #2e737d;
  text-transform: uppercase;

  &:hover{
    text-decoration: underline;
  }
}

.modal-link {

}

.last-add{
  width: 100%;
  margin-top: 50/1025*100%;
  max-width: 1025px;
}
.last-add__filter{
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  @include media-custom(1024px){
  flex-wrap: wrap;
  };
}

.last-add__tab{
  width: 100%;

  &.hidden{
    display: none;
  }
}

.last-add__filter-item{
  flex: 1;
  white-space: nowrap;
  font-size: 37px;
  font-family: 'Univers';
  color: #fff;
  text-align: center;
  @include media-custom(1024px){
    font-size: 25px;
  };
  @include media(tel){
    font-size: 20px;
  };


  &:hover,
  &.active{
    a{
      background-color: $theme-color;
    }
  }

  a{
    background-color: #acacac;
    display: block;
    padding: em(((73-37)/2), 37) em(10,37);
  }
}
.last-add__select{
  width: 100%;
  margin-top: 29/1025*100%;
  margin-bottom: 35/1025*100%;
  .show-last{
    justify-content: flex-start;

    em{
      color: #404040;
      margin-right: 43px;
    }
  }
}
.last-add__content{
  width: 100%;
}

.last-views{
  width: 100%;
  max-width: 1025px;
}
.last-views__title{
  font-size: 38px;
  color: #242424;
  font-family: 'Univers';
  margin-top: 39/1025*100%;
  margin-bottom: 65/1025*100%;
}
.last-views__content{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  @include media(tel){
    justify-content: space-between;
  };
}
.last-views__item{

  text-align: center;
  display: flex;
  flex-direction: column;
  width: 185px;
  @include media(tablet){
    margin-bottom: 25px;
  };
  &:not(:last-child){
    margin-right: 25px;
    @include media(tel){
      margin-right: 0;
    };
  }
}
.last-views__image{
  height: 201px;
  overflow: hidden;
  margin-bottom: 15px;
}
.last-views__name{
  color: #4a4a4a;
  font-weight: 700;
  font-size: 18px;
  transition: all 0.3s;
  .last-views__item:hover &{
    color: $theme-color;
  }
}
