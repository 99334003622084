//CALIBRI
  //LIGHT
  @font-face {
    font-family: 'Calibri';
    src: local('Calibri Light'),
         url('../fonts/Calibri/CalibriLight/CalibriLight.woff2') format('woff2'),
         url('../fonts/Calibri/CalibriLight/CalibriLight.ttf')  format('truetype'), /* Только если нужна поддержка старых Android, иначе закомментировать */
         url('../fonts/Calibri/CalibriLight/CalibriLight.woff') format('woff');
    font-weight: 300;
    font-style: normal;
  }
  //REGULAR
  @font-face {
    font-family: 'Calibri';
    src: local('Calibri'),
         url('../fonts/Calibri/CalibriRegular/CalibriRegular.woff2') format('woff2'),
         url('../fonts/Calibri/CalibriRegular/CalibriRegular.ttf')  format('truetype'), /* Только если нужна поддержка старых Android, иначе закомментировать */
         url('../fonts/Calibri/CalibriRegular/CalibriRegular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
  }
  //BOLD
  @font-face {
    font-family: 'Calibri';
    src: local('Calibri'),
         url('../fonts/Calibri/CalibriBold/CalibriBold.woff2') format('woff2'),
         url('../fonts/Calibri/CalibriBold/CalibriBold.ttf')  format('truetype'), /* Только если нужна поддержка старых Android, иначе закомментировать */
         url('../fonts/Calibri/CalibriBold/CalibriBold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
  }
  //Lato
    //Hairline
    // @font-face {
    //   font-family: 'LatoHairline';
    //   src: local('Lato Hairline'),
    //        url('../fonts/Lato/LatoHairline/LatoHairline.woff2') format('woff2'),
    //        url('../fonts/Lato/LatoHairline/LatoHairline.ttf')  format('truetype'), /* Только если нужна поддержка старых Android, иначе закомментировать */
    //        url('../fonts/Lato/LatoHairline/LatoHairline.woff') format('woff');
    //   font-weight: 50;
    //   font-style: normal;
    // }
    //Light
    @font-face {
      font-family: 'Lato';
      src: local('Lato Light'),
           url('../fonts/Lato/LatoLight/LatoLight.woff2') format('woff2'),
           url('../fonts/Lato/LatoLight/LatoLight.ttf')  format('truetype'), /* Только если нужна поддержка старых Android, иначе закомментировать */
           url('../fonts/Lato/LatoLight/LatoLight.woff') format('woff');
      font-weight: 300;
      font-style: normal;
    }
    //Thin
    @font-face {
      font-family: 'Lato';
      src: local('Lato Thin'),
           url('../fonts/Lato/LatoThin/LatoThin.woff2') format('woff2'),
           url('../fonts/Lato/LatoThin/LatoThin.ttf')  format('truetype'), /* Только если нужна поддержка старых Android, иначе закомментировать */
           url('../fonts/Lato/LatoThin/LatoThin.woff') format('woff');
      font-weight: 100;
      font-style: normal;
    }

//UNIVERS
  // MEDIUM
@font-face {
  font-family: 'Univers';
  src: local('Univers Condensed'),
       url('../fonts/Univers/UniversMedium/UniversMedium.woff2') format('woff2'),
       url('../fonts/Univers/UniversMedium/UniversMedium.ttf')  format('truetype'), /* Только если нужна поддержка старых Android, иначе закомментировать */
       url('../fonts/Univers/UniversMedium/UniversMedium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
  // BOLD
@font-face {
  font-family: 'Univers';
  src: local('Univers Condensed'),
       url('../fonts/Univers/UniversBold/UniversBold.woff2') format('woff2'),
       url('../fonts/Univers/UniversBold/UniversBold.ttf')  format('truetype'), /* Только если нужна поддержка старых Android, иначе закомментировать */
       url('../fonts/Univers/UniversBold/UniversBold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}



// {
// 	"extrathin": 50,
// 	"hairline": 50,
// 	"ultrathin": 50,
// 	"thin": 100,
// 	"extralight": 200,
// 	"ultralight": 200,
// 	"light": 300,
// 	"book": 400,
// 	"normal": 400,
// 	"plain": 400,
// 	"regular": 400,
// 	"medium": 500,
// 	"demibold": 600,
// 	"semibold": 600,
// 	"bold": 700,
// 	"extrabold": 800,
// 	"heavy": 800,
// 	"ultrabold": 800,
// 	"black": 900,
// 	"extrablack": 950,
// 	"fat": 950,
// 	"poster": 950,
// 	"ultrablack": 950
// }
